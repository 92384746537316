import React, {
  useContext, useEffect, useState, useRef,
} from 'react';
import { useParams } from 'react-router-dom';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { SOURCES_URL } from '../../Constants/URLS';

function AdditionalTextView() {
  const { id } = useParams();
  const userContext = useContext(UserContext);

  const api = useRef(apiUtilsHook(userContext));

  const [source, setSource] = useState(null);
  const [error, setError] = useState('');
  const hasFetched = useRef(false);

  useEffect(() => {
    if (hasFetched.current) return;

    hasFetched.current = true;
    api.current
      .get(`${SOURCES_URL}${id}/`)
      .then((response) => {
        setSource(response.data);
      })
      .catch((err) => {
        setError(err.message || 'Error fetching source');
      });
  }, [id]);

  if (error) {
    return (
      <div className="error-container">
        <p>{error}</p>
      </div>
    );
  }

  if (!source) {
    return (
      <div className="loading-container">
        <p>Loading source...</p>
      </div>
    );
  }

  return (
    <div className="additional-text-view">
      <div className="content-container">
        <h1>{source.title}</h1>
        <p>{source.additional_text}</p>
      </div>
    </div>
  );
}

export default AdditionalTextView;
