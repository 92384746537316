import React from 'react';
import PropTypes from 'prop-types';
import { FormControlLabel, Switch } from '@mui/material';

function StatusSwitch({ status, setStatus, disabled }) {
  return (
    <FormControlLabel
      control={<Switch checked={status} disabled={disabled} onChange={setStatus} />}
      label={status ? 'Online' : 'Offline'}
      className={`status-switch ${disabled ? 'disabled' : ''} ${status ? 'online' : ''}`}
    />
  );
}

StatusSwitch.propTypes = {
  status: PropTypes.bool.isRequired,
  setStatus: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

StatusSwitch.defaultProps = {
  disabled: false,
};

export default StatusSwitch;
