import React from 'react';
import PropTypes from 'prop-types';

function FormPanel({
  title, subtitle, children, ...props
}) {
  return (
    // eslint-disable-next-line
    <div className="form-panel" {...props}>
      <div className="form-panel-header">
        <h2>
          {title}
        </h2>
        {subtitle && <h3>{subtitle}</h3>}
      </div>
      <div className="form-panel-body">
        {children}
      </div>
    </div>
  );
}

FormPanel.propTypes = {
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string,
  children: PropTypes.node.isRequired,
};

FormPanel.defaultProps = {
  subtitle: null,
};

export default FormPanel;
