import React from 'react';
import PropTypes from 'prop-types';
import { IconCheck } from '@tabler/icons-react';

function Checkbox({
  id, checked, onChange, children,
}) {
  return (
    <>
      <input
        type="checkbox"
        checked={checked}
        onChange={onChange}
        id={id}
        hidden
      />
      <label className="custom-checkbox" htmlFor={id}>
        <div className="checkbox">
          <IconCheck />
        </div>
        {children}
      </label>
    </>
  );
}

Checkbox.propTypes = {
  id: PropTypes.string.isRequired,
  checked: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node,
};

Checkbox.defaultProps = {
  children: null,
};

export default Checkbox;
