import { useLocation, useParams, useNavigate } from 'react-router-dom';
import React, { useState, useContext, useEffect } from 'react';
import {
  Button, CircularProgress, Container, Grid,
} from '@mui/material';
import { IconMessages, IconSend, IconZoomQuestion } from '@tabler/icons-react';
import './ChatView.scss';

import { PulseLoader } from 'react-spinners';
import { CONVERSATIONS_URL, FAQ_URL, MESSAGES_URL } from '../../Constants/URLS';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import Message from '../../Components/Chat/Message';
import BackofficeTitle from '../../Components/Backoffice/BackofficeTitle';

function ChatView() {
  const location = useLocation();
  const { state } = location;

  const { conversationId } = useParams();
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);

  const [messages, setMessages] = useState([]);

  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  const [faqs, setFaqs] = useState([]);

  useEffect(() => {
    if (faqs.length === 0) {
      api.get(`${FAQ_URL}?online=true`).then((r) => {
        setFaqs(r.data.results);
      });
    }
  }, []);

  const sendMessage = async (msg) => {
    try {
      if (!msg.trim()) return;

      // Create new message object
      const newMessage = { message: msg, role: 'user', user: userContext.user.first_name };

      // Update conversation state with new message
      setMessages((prevMessages) => [...prevMessages, newMessage]);

      const data = {
        message: msg,
      };

      if (conversationId) {
        data.conversation = conversationId;
      }

      const response = await api.post(MESSAGES_URL, data);

      // Update conversation with API response
      setMessages((prevMessages) => [
        ...prevMessages.slice(0, -1),
        ...response.data,
      ]);

      // If this is a new conversation, update the URL with the new conversation ID
      if (!conversationId && response.data.length > 0) {
        navigate(`/chat/${response.data[0].conversation}`);
      }
    } catch (err) {
      setError('Failed to send message. Please try again.');
      // eslint-disable-next-line no-console
      console.error('Error sending message:', err);
    }
  };

  const addEscalationToConversation = (escalationMsg) => {
    // Add escalation message to messages list
    // Only add it if the message ID is not already in the list
    if (!messages.find((msg) => msg.id === escalationMsg.id)) {
      setMessages((prevMessages) => [...prevMessages, escalationMsg]);
    }
  };

  const fetchConversation = async () => {
    try {
      setLoading(true);
      const response = await api.get(`${CONVERSATIONS_URL}${conversationId}/`);
      setMessages(response.data.messages);

      if (state?.messageId && state?.showSources) {
        // eslint-disable-next-line max-len
        setMessages((prevMessages) => prevMessages.map((msg) => (msg.id === state.messageId ? { ...msg, showSources: true } : msg)));
      }
    } catch (err) {
      setError('Failed to load conversation. Please refresh the page.');
      // eslint-disable-next-line no-console
      console.error('Error loading conversation:', err);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (conversationId) {
      fetchConversation();
    } else {
      setLoading(false);
      setMessages([]);
    }
  }, [conversationId]);

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage(message);
      setMessage('');
    }
  };

  if (loading) {
    return (
      <Container maxWidth="lg" className="chat-view">
        <div className="loading-container">
          <CircularProgress />
        </div>
      </Container>
    );
  }

  if (error) {
    return (
      <Container maxWidth="lg" className="chat-view">
        <div className="error-container">
          <p className="error-message">{error}</p>
          <Button
            variant="contained"
            color="primary"
            onClick={fetchConversation}
          >
            Retry
          </Button>
        </div>
      </Container>
    );
  }

  return (
    <Container maxWidth="lg" className="chat-view">
      <BackofficeTitle
        title="Virtuele Assistent"
        icon={<IconMessages />}
      />
      <Grid container spacing={4} sx={{ mt: -1 }}>
        <Grid item xs={12} lg={4}>
          <div className="faq">
            <div className="title">
              <div className="icon">
                <IconZoomQuestion />
              </div>
              <h1>
                Voorgestelde vragen
              </h1>
            </div>
            <div className="content">
              {faqs.map((faq) => (
              // eslint-disable-next-line
              <div
                key={faq.id}
                className="faq-question"
                onClick={() => sendMessage(faq.question)}
              >
                {faq.question}
              </div>
              ))}
            </div>
            <Button
              onClick={() => navigate('/chat')}
              className="btn btn-green"
              fullWidth
            >
              Nieuw gesprek
            </Button>
          </div>
        </Grid>
        <Grid item xs={12} lg={8}>
          <div className="chat-container">
            <div className="messages">
              {messages.length > 0 && messages.slice().reverse()[0].role === 'user' && (
                <div className="message assistant">
                  <span className="sender">Chatbot</span>
                  <PulseLoader size={5} className="message-loader" speedMultiplier={0.7} />
                </div>
              )}
              {messages.slice().reverse().map((chatMessage) => (
                <Message
                  key={chatMessage.id}
                  message={chatMessage}
                  escalationCallback={addEscalationToConversation}
                />
              ))}
              <div className="message assistant">
                <span className="sender">Chatbot</span>
                <p className="message-content">
                  Welkom bij de chatfunctie van het AO platform. Stel mij je vraag!
                </p>
              </div>
            </div>
            <div className="chat-bottom">
              <div className="chat-input">
                <textarea
                  placeholder="Voer hier je bericht in..."
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyDown={handleKeyPress}
                  // disabled={messages.length > 0 && messages.slice().reverse()[0].}
                />
                <Button
                  onClick={() => {
                    sendMessage(message);
                    setMessage('');
                  }}
                  disabled={!message.trim()}
                >
                  <IconSend />
                </Button>
              </div>
              <p className="disclaimer">
                De Virtuele Assistent is een AI-systeem dat antwoorden genereert, maar deze kunnen
                foutief of onvolledig zijn. Controleer altijd de antwoorden en bronnen.
              </p>
            </div>
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}

export default ChatView;
