import React, { useState, useContext } from 'react';
import {
  Container, Button, Box, Grid,
} from '@mui/material';
import Swal2 from 'sweetalert2';
import { IconTag } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../../Utils/ApiUtilsHook';
import { TOPICS_URL } from '../../../../Constants/URLS';
import BackofficeTitle from '../../../../Components/Backoffice/BackofficeTitle';
import FormLabel from '../../../../Components/Backoffice/FormLabel';
import FormInput from '../../../../Components/Backoffice/FormInput';
import FormTextArea from '../../../../Components/Backoffice/FormTextArea';

function TopicAddView() {
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const navigate = useNavigate();

  const submitTopic = async () => {
    if (!name || !description) {
      Swal2.fire({
        title: 'Fout',
        text: 'Vul alle verplichte velden in.',
        icon: 'error',
      });
      return;
    }

    api.post(TOPICS_URL, { name, description }).then((r) => {
      navigate(`/backoffice/topics/${r.data.id}`);
    });
  };

  return (
    <Container maxWidth="lg" sx={{ py: 6, minHeight: 'calc(100vh - 180px)' }}>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          mb: 3,
        }}
      >
        <BackofficeTitle title="Voeg een onderwerp toe" icon={<IconTag />} />
        <div>
          <Button
            className="btn btn-ghost"
            onClick={() => navigate('/backoffice/topics')}
          >
            Annuleren
          </Button>
          <Button
            className="btn btn-green"
            sx={{ ml: 1 }}
            onClick={submitTopic}
          >
            Opslaan
          </Button>
        </div>
      </Box>
      <Grid container spacing={0}>
        <Grid item xs={12} md={6}>
          <FormLabel label="Naam" required />
          <FormInput
            value={name}
            onChange={setName}
            placeholder="Voer de naam van het onderwerp in..."
            limit={100}
          />
          <FormLabel label="Beschrijving" required style={{ marginTop: 20 }} />
          <FormTextArea
            value={description}
            onChange={setDescription}
            placeholder="Voer de beschrijving van het onderwerp in..."
            rows={5}
          />
        </Grid>
      </Grid>
    </Container>
  );
}

export default TopicAddView;
