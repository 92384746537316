import React, { useState } from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import {
  IconFileText, IconChevronDown, IconChevronUp,
  IconAlertTriangleFilled, IconThumbDownFilled, IconThumbUpFilled,
} from '@tabler/icons-react';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';

function ConversationMessage({ message, sender }) {
  const [showSources, setShowSources] = useState(false);
  const navigate = useNavigate();

  const getSatisfactionStatus = () => {
    if (message.user_satisfied) {
      return 'satisfied';
    }
    if (message.user_dissatisfied) {
      return 'dissatisfied';
    }
    return 'unevaluated';
  };

  return (
    // add "alert-message" class for alerts
    <div className="conversation-message">
      <div className={`message-content ${message.role}`}>
        <div className="message-header">
          <span
            className="author"
          >
            {sender}
          </span>
          <span className="time">{moment(message.created_at).format('HH:mm')}</span>
        </div>
        <p><ReactMarkdown>{message.message}</ReactMarkdown></p>
        {message.sources && message.sources.length > 0 && (
          // eslint-disable-next-line
          <div
            className="sources"
            onClick={() => setShowSources(!showSources)}
          >
            <div className="sources-count">
              {message.sources.length}
            </div>
            <div className="sources-icon">
              <IconFileText />
            </div>
            <div className="sources-text">
              Bijgevoegde bronnen
            </div>
            {showSources ? <IconChevronUp className="sources-caret" /> : <IconChevronDown className="sources-caret" />}
          </div>
        )}
        {showSources && message.sources.map((source) => (
          // eslint-disable-next-line
          <div
            className="source"
            onClick={() => navigate(`/backoffice/sources/${source.id}`)}
          >
            <div className="icon">
              <IconFileText />
            </div>
            <h4>
              {source.title}
            </h4>
          </div>
        ))}
      </div>
      {message.sender === 'chatbot' && (
        <div className={`satisfaction ${getSatisfactionStatus(message)}`}>
          {getSatisfactionStatus() === 'dissatisfied' ? (
            <IconThumbDownFilled />
          ) : (
            <IconThumbUpFilled />
          )}
        </div>
      )}
      <div className="alert-icon">
        <IconAlertTriangleFilled />
      </div>
    </div>
  );
}

ConversationMessage.propTypes = {
  // eslint-disable-next-line
  message: PropTypes.object.isRequired,
  sender: PropTypes.string.isRequired,
};

export default ConversationMessage;
