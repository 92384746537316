import React, { useContext, useEffect, useState } from 'react';
import {
  Button, Container, Grid,
} from '@mui/material';
import { useNavigate, useParams } from 'react-router-dom';
import moment from 'moment';
import {
  IconAlertTriangleFilled,
} from '@tabler/icons-react';
import apiUtilsHook from '../../../../Utils/ApiUtilsHook';
import { COMMENTS_URL, CONVERSATIONS_URL } from '../../../../Constants/URLS';
import { UserContext } from '../../../../Providers/UserProvider/UserProvider';
import ConversationMessage from '../../../../Components/Backoffice/ConversationMessage';

export default function ConversationView() {
  const { id } = useParams();
  const [conversation, setConversation] = useState(null);
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const navigate = useNavigate();
  const [newComment, setNewComment] = useState('');

  const fetchConversation = () => {
    api.get(`${CONVERSATIONS_URL}${id}`).then((response) => {
      setConversation(response.data);
    }).catch(() => {
      navigate('/backoffice/conversations');
    });
  };

  useEffect(() => {
    fetchConversation();
  }, []);

  const addComment = () => {
    api.post(COMMENTS_URL, {
      conversation: conversation.id,
      text: newComment,
    }).then(() => {
      fetchConversation();
    });
  };

  return conversation && (
    <Container className="conversation-view" sx={{ mt: 1 }}>
      {conversation.alert && (
        <div className="alert">
          <h4>
            <IconAlertTriangleFilled />
            {' '}
            Gesprekprobleem gedetecteerd
          </h4>
          <p>
            De chatbot kon de vraag van de gebruiker niet begrijpen of beantwoorden.
            Bekijk de conversatie en onderneem passende actie om het probleem op te lossen.
          </p>
          {/* TODO: implement functionality */}
          <Button
            className="btn btn-warning"
          >
            Oplossen
          </Button>
          <Button
            className="btn btn-ghost"
            sx={{ ml: 1 }}
          >
            Kijk in het gesprek
          </Button>
        </div>
      )}
      <Grid container spacing={6} pt={4} pb={9}>
        <Grid item xs={12} lg={4}>
          <div className="conversation-panel">
            <div className="panel-header">
              <h2>
                {conversation.user.full_name}
              </h2>
              <span className="timestamps">
                {moment(conversation.created_at).format('DD/MM/YYYY HH:mm')}
                {' — '}
                {moment(conversation.last_message_time).format('DD/MM/YYYY HH:mm')}
              </span>
            </div>
            <div className="panel-content">
              <p>
                Onderwerpen:
                {' '}
                {conversation.topics}
              </p>
              <p>
                Email:
                {' '}
                <a href={`mailto:${conversation.user.email}`}>{conversation.user.email}</a>
              </p>
              {conversation.user.phone_number && (
                <p>
                  Telefoon:
                  {' '}
                  <a href={`tel:${conversation.user.phone_number}`}>{conversation.user.phone_number}</a>
                </p>
              )}
              {conversation.user.office_name && (
                <p>
                  Bedfrijf:
                  {' '}
                  {conversation.user.office_name}
                </p>
              )}
              {conversation.user.position_role && (
                <p>
                  Functietitel:
                  {' '}
                  {conversation.user.position_role}
                </p>
              )}
            </div>
          </div>
          <div className="conversation-panel">
            <div className="panel-header">
              <h2>
                Opmerkingen
                <span className="badge">
                  {conversation.comments.length}
                </span>
              </h2>
            </div>
            <div className="panel-content">
              {conversation.comments.map((comment) => (
                <div className="comment">
                  <div className="comment-header">
                    <span className="author">{comment.user.full_name}</span>
                    <span className="time">{moment(comment.created_at).format('DD/MM/YYYY, HH:mm')}</span>
                  </div>
                  <p>
                    {comment.text}
                  </p>
                </div>
              ))}
              <div className="new-comment">
                <label>
                  Nieuwe opmerking
                </label>
                <textarea
                  value={newComment}
                  onChange={(e) => setNewComment(e.target.value)}
                  placeholder="Voer hier een opmerking in"
                  rows={3}
                />
                <Button
                  className="btn btn-green"
                  sx={{ mt: 2 }}
                  onClick={addComment}
                >
                  Opslaan
                </Button>
              </div>
            </div>
          </div>
        </Grid>
        <Grid item xs={12} lg={8}>
          <div className="chat">
            {conversation.messages.map((message) => (
              <ConversationMessage
                message={message}
                sender={message.role === 'assistant' ? 'Chatbot' : conversation.user.first_name}
              />
            ))}
          </div>
        </Grid>
      </Grid>
    </Container>
  );
}
