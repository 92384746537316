import React, { useContext, useState } from 'react';
import PropTypes from 'prop-types';
import {
  Dialog, DialogTitle, DialogContent, Button,
} from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { SOURCES_URL, TOPICS_URL } from '../../../Constants/URLS';
import { UserContext } from '../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../Utils/ApiUtilsHook';
import Checkbox from '../Checkbox';

export default function TopicSelectionModal({ topics, sourceId, onClose }) {
  // Utils
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const navigate = useNavigate();

  const open = topics.length > 0;

  const [selectedTopics, setSelectedTopics] = useState([]);

  const toggleTopic = (topic) => {
    if (selectedTopics.includes(topic)) {
      setSelectedTopics(selectedTopics.filter((t) => t !== topic));
    } else {
      setSelectedTopics([...selectedTopics, topic]);
    }
  };

  const submit = () => {
    Promise.all(
      selectedTopics.map((topic) => (topic.id
        ? Promise.resolve(topic.id)
        : api.post(TOPICS_URL, {
          name: topic.name,
          description: topic.description,
        }).then((response) => response.data.id))),
    )
      .then((createdTopicIds) => api.patch(`${SOURCES_URL}${sourceId}/`, {
        topics: createdTopicIds,
      }))
      .then(() => {
        if (onClose) {
          onClose(true);
          return;
        }

        navigate(`/backoffice/sources/${sourceId}`);
      });
  };

  if (!topics || topics.length === 0) {
    return null;
  }

  return (
    <Dialog
      open={open}
      onClose={() => navigate(`/backoffice/sources/${sourceId}`)}
      className="topic-selection-modal"
    >
      <DialogTitle>Selecteer Onderwerpen</DialogTitle>
      <DialogContent>
        {topics.map((topic, index) => (
          <div key={index} style={{ marginBottom: '10px' }}>
            <Checkbox
              checked={selectedTopics.includes(topic)}
              onChange={() => toggleTopic(topic)}
              id={topic.name}
            >
              {topic.name}
            </Checkbox>
            <p>
              {topic.description}
            </p>
          </div>
        ))}
        <Button className="btn btn-green" onClick={submit}>
          Opslaan
        </Button>
        <Button
          onClick={() => {
            if (onClose) {
              onClose(false);
              return;
            }

            navigate(`/backoffice/sources/${sourceId}`);
          }}
          className="btn btn-ghost"
          sx={{ ml: 1 }}
        >
          Annuleren
        </Button>
      </DialogContent>
    </Dialog>
  );
}

TopicSelectionModal.propTypes = {
  topics: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      description: PropTypes.string,
    }),
  ).isRequired,
  sourceId: PropTypes.string,
  onClose: PropTypes.func,
};

TopicSelectionModal.defaultProps = {
  sourceId: null,
  onClose: null,
};
