import React, { useState, useEffect, useContext } from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  TableSortLabel,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Box,
  Container,
} from '@mui/material';
import moment from 'moment';
import {
  IconPlus, IconQuestionMark, IconStar, IconStarFilled,
} from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../../Utils/ApiUtilsHook';
import { FAQ_URL } from '../../../../Constants/URLS';
import { containerStyles } from '../../../../Components/TableStyles/TableStyles';
import BackofficeTitle from '../../../../Components/Backoffice/BackofficeTitle';
import FormLabel from '../../../../Components/Backoffice/FormLabel';
import FormTextArea from '../../../../Components/Backoffice/FormTextArea';

function FAQTableView() {
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);

  const [faqs, setFaqs] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();
  const [search, setSearch] = useState('');

  const [newQuestion, setNewQuestion] = useState('');

  const fetchFAQs = async () => {
    try {
      let params = `?page=${page + 1}&page_size=${rowsPerPage}`;
      if (orderBy) {
        params += `&ordering=${order === 'desc' ? '-' : ''}${orderBy}`;
      }
      if (search) {
        params += `&search=${search}`;
      }

      const response = await api.get(`${FAQ_URL}${params}`);
      setFaqs(response.data.results);
      setTotalCount(response.data.count);
    } catch (error) {
      console.error('Error fetching FAQs:', error);
    }
  };

  useEffect(() => {
    fetchFAQs();
  }, [page, rowsPerPage, order, orderBy, search]);

  const handleRequestSort = (property) => {
    if (property === orderBy) {
      if (order === 'asc') {
        setOrder('desc');
      } else {
        setOrderBy('');
      }
    } else {
      setOrderBy(property);
      setOrder('asc');
    }
  };

  const handleSubmit = async () => {
    await api.post(FAQ_URL, { question: newQuestion });
    window.location.reload();
  };

  useEffect(() => {
    setNewQuestion('');
  }, [isModalOpen]);

  const toggleFeatured = async (e, faq) => {
    e.preventDefault();
    e.stopPropagation();
    await api.patch(`${FAQ_URL}${faq.id}/`, { online: !faq.online });
    fetchFAQs();
  };

  return (
    <Container
      maxWidth="lg"
      sx={{
        ...containerStyles,
        minHeight: 'calc(100vh - 300px)',
      }}
      className="faq-view"
    >
      <Box sx={{ display: 'flex', gap: 2, mb: 3 }}>
        <BackofficeTitle title="Voorgestelde vragen" icon={<IconQuestionMark />} />
        <input
          placeholder="Zoek..."
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
            setPage(0);
          }}
          className="backoffice-search"
        />
        <Button
          onClick={() => setIsModalOpen(true)}
          startIcon={<IconPlus />}
          className="btn btn-green"
        >
          Nieuwe vraag
        </Button>
      </Box>

      <TableContainer className="backoffice-table">
        <Table sx={{ tableLayout: 'fixed' }}>
          <TableHead className="table-header">
            <TableRow>
              <TableCell sx={{ width: '10%' }}>
                Uitgelicht
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'question'}
                  direction={orderBy === 'question' ? order : 'asc'}
                  onClick={() => handleRequestSort('question')}
                >
                  Vraag
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'used'}
                  direction={orderBy === 'used' ? order : 'asc'}
                  onClick={() => handleRequestSort('used')}
                >
                  Gebruikt
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'created_at'}
                  direction={orderBy === 'created_at' ? order : 'asc'}
                  onClick={() => handleRequestSort('created_at')}
                >
                  Gemaakt op
                </TableSortLabel>
              </TableCell>
              <TableCell>
                Gemaakt door
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {faqs.map((faq) => (
              <TableRow key={faq.id} hover onClick={() => navigate(`/backoffice/faq/${faq.id}`)}>
                <TableCell>
                  <Button
                    className={`featured-btn ${faq.online ? 'active' : ''}`}
                    onClick={(e) => toggleFeatured(e, faq)}
                  >
                    {faq.online ? <IconStarFilled /> : <IconStar />}
                  </Button>
                </TableCell>
                <TableCell sx={{
                  wordBreak: 'break-word',
                  whiteSpace: 'normal',
                }}
                >
                  {faq.question}
                </TableCell>
                <TableCell>{faq.used}</TableCell>
                <TableCell>
                  {moment(faq.created_at).format('DD MMMM YYYY, HH:mm')}
                </TableCell>
                <TableCell>
                  {faq.created_by}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
        <TablePagination
          component="div"
          count={totalCount}
          page={page}
          onPageChange={(event, newPage) => setPage(newPage)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
          }}
          labelRowsPerPage="Rijen per pagina:"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} van ${count !== -1 ? count : `meer dan ${to}`}`}
        />
      </TableContainer>

      <Dialog
        open={isModalOpen}
        onClose={() => setIsModalOpen(false)}
        maxWidth="sm"
        fullWidth
        className="new-faq-dialog"
      >
        <DialogTitle>
          Nieuwe Vraag Toevoegen
        </DialogTitle>
        <DialogContent>
          <FormLabel label="Vraag" required />
          <FormTextArea
            value={newQuestion}
            onChange={setNewQuestion}
            placeholder="Voer hier de vraag in..."
            rows={3}
          />
          <div className="warning-text">
            Let op, om een antwoord te geven op een voorgestelde vraag moet er altijd een
            bijbehorende bron worden geüpload via de Bronnen-pagina.
          </div>
          <Button
            className="btn btn-green"
            sx={{ mt: 2 }}
            onClick={handleSubmit}
            disabled={!newQuestion}
          >
            Maken
          </Button>
          <Button
            onClick={() => setIsModalOpen(false)}
            className="btn btn-ghost"
            sx={{ mt: 2, ml: 1 }}
          >
            Annuleren
          </Button>
        </DialogContent>
      </Dialog>
    </Container>
  );
}

export default FAQTableView;
