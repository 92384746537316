import React, {
  useState, useEffect, useContext,
} from 'react';
import './UserTableView.scss';
import {
  TbSend, TbTrash, TbUsers,
} from 'react-icons/tb';
import {
  Modal, Button, Container, Grid,
} from '@mui/material';
import Swal from 'sweetalert2';
import { DataGrid, nlNL } from '@mui/x-data-grid';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import SignUpApi from '../../APIs/SignUpApis';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import {
  USER_URL, JOB_ROLES_URL, PERMISSION_ROLES, USER_TABLE_URL,
} from '../../Constants/URLS';
import AccessDeniedMessage from '../../Components/AccessDeniedMessage';
import LoadingScreen from '../LoadingScreen';

function UserTableView() {
  const [userData, setUserData] = useState([]);
  const [searchInput, setSearchInput] = useState('');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [count, setCount] = useState(0);

  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [inviteEmail, setInviteEmail] = useState('');

  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const SignUpAPIs = SignUpApi(userContext);

  // Define the roles that have access to this view
  const allowedRoles = ['Staff', 'Admin'];
  const hasAccess = allowedRoles.includes(userContext.user.role);

  const [jobRoles, setJobRoles] = useState({});
  const [permissionRoles, setPermissionRoles] = useState({});

  // Extracted function to fetch user data
  const fetchUserData = () => {
    api.get(USER_URL, {
      params: {
        search: searchInput,
        page: page + 1,
        page_size: rowsPerPage,
      },
    }).then((response) => {
      setUserData(response.data.results);
      setCount(response.data.count);
    }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error('There was an error fetching the user data', error);
    });
  };

  // Function to handle delete confirmation and action
  const deleteUser = (userId) => {
    Swal.fire({
      icon: 'question',
      title: 'Bevestiging',
      text: 'Weet u zeker dat u deze gebruiker wilt verwijderen?',
      showCancelButton: true,
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nee',
    }).then((result) => {
      if (result.isConfirmed) {
        api.del(`${USER_TABLE_URL}${userId}/`)
          .then(() => {
            // Refresh user data
            fetchUserData();
            Swal.fire({
              icon: 'success',
              title: 'Gelukt',
              text: 'Gebruiker succesvol verwijderd.',
            });
          })
          .catch((error) => {
            // eslint-disable-next-line no-console
            console.error('Error deleting user', error);
          });
      }
    });
  };

  // Handle opening and closing of the invite modal
  const handleOpenInviteModal = () => setInviteModalOpen(true);
  const handleCloseInviteModal = () => {
    setInviteModalOpen(false);
    setInviteEmail('');
  };

  const sendInvitation = () => {
    SignUpAPIs.sendInvitation(inviteEmail)
      .then((response) => {
        if (response.status === 201) {
          setInviteModalOpen(false);
          setInviteEmail('');
          Swal.fire({
            icon: 'success',
            title: 'Gelukt',
            text: 'Uitnodiging succesvol verzonden.',
          });
        } else {
          response.json().then((data) => {
            setInviteModalOpen(false);
            setInviteEmail('');
            Swal.fire({
              icon: 'error',
              title: 'Error',
              text: data.message || 'Kan de uitnodiging niet verzenden.',
            });
          });
        }
      })
      .catch(() => {
        setInviteModalOpen(false);
        setInviteEmail('');
        Swal.fire({
          icon: 'error',
          title: 'Error',
          text: 'Er is een fout opgetreden tijdens het verzenden van de uitnodiging.',
        });
      });
  };

  useEffect(() => {
    // Fetch data only if the user has access
    if (hasAccess) {
      fetchUserData();
    }
  }, [hasAccess, searchInput, rowsPerPage, page]);

  useEffect(() => {
    setPage(0);
  }, [searchInput, rowsPerPage]);

  useEffect(() => {
    // Fetch job roles
    api.get(JOB_ROLES_URL)
      .then((response) => {
        const rolesMapping = response.data.reduce((acc, role) => {
          acc[role.value] = role.label;
          return acc;
        }, {});
        setJobRoles(rolesMapping);
      });

    // Fetch permission roles
    api.get(PERMISSION_ROLES)
      .then((response) => {
        const rolesMapping = response.data.reduce((acc, role) => {
          acc[role.value] = role.label;
          return acc;
        }, {});
        setPermissionRoles(rolesMapping);
      });
  }, []);

  if (!userContext.user || !userContext.user.id) {
    return <LoadingScreen />;
  }

  if (!hasAccess) {
    return <AccessDeniedMessage />;
  }

  const columns = [
    {
      field: 'id', headerName: 'ID', width: 70, renderCell: (params) => `#${params.value}`,
    },
    {
      field: 'full_name_infix', headerName: 'Naam', flex: 1, renderCell: (params) => <b>{params.value}</b>,
    },
    {
      field: 'email', headerName: 'Email', flex: 1,
    },
    { field: 'phone_number', headerName: 'Telefoonnummer', width: 130 },
    {
      field: 'position_role',
      headerName: 'Beroep',
      flex: 1,
      renderCell: (params) => jobRoles[params.row.position_role] || params.row.position_role,
    },
    { field: 'last_activity', headerName: 'Laatste Activiteit', width: 140 },
    {
      field: 'role',
      headerName: 'Toegang',
      width: 90,
      renderCell: (params) => permissionRoles[params.row.role] || params.row.role,
    },
    {
      headerName: 'Acties',
      sortable: false,
      renderCell: (params) => (
        <Button
          onClick={() => deleteUser(params.row.id)}
          className="btn-delete"
          sx={{ float: 'right' }}
        >
          <TbTrash />
        </Button>
      ),
    },
  ];

  return (
    <Container maxWidth="lg" sx={{ mt: 6, mb: 9 }}>
      <div className="backoffice-view">
        <div className="heading">
          <TbUsers />
          <h1>Gebruikers</h1>
        </div>
        <Grid container spacing={3} sx={{ mb: 3 }}>
          <Grid item xs={12} lg={9}>
            <input
              type="text"
              placeholder="Zoeken..."
              value={searchInput}
              onChange={(e) => setSearchInput(e.target.value)}
              className="search-bar"
            />
          </Grid>
          <Grid item xs={12} lg={3}>
            <Button
              onClick={handleOpenInviteModal}
              className="btn btn-green btn-block"
              startIcon={<TbSend />}
            >
              Uitnodiging Sturen
            </Button>
          </Grid>
        </Grid>
        <DataGrid
          sx={{ mt: 3 }}
          rows={userData}
          columns={columns}
          initialState={{
            pagination: {
              paginationModel: { page: 0, pageSize: 25 },
            },
          }}
          page={page}
          onPageChange={(newPage) => setPage(newPage)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(newRowsPerPage) => setRowsPerPage(newRowsPerPage)}
          pageSizeOptions={[5, 10, 25, 50]}
          disableRowSelectionOnClick
          isCellSelectable={() => false}
          localeText={nlNL.components.MuiDataGrid.defaultProps.localeText}
          rowCount={count}
        />
        {/* Invitation Modal */}
        <Modal open={inviteModalOpen} onClose={handleCloseInviteModal} className="invite-modal">
          <div className="modal-content">
            <label>
              Emailadres
              <span className="required">*</span>
            </label>

            <input
              type="email"
              value={inviteEmail}
              placeholder="E.g. jeroen@gmail.com"
              onChange={(e) => setInviteEmail(e.target.value)}
            />
            <Button
              onClick={sendInvitation}
              className="btn btn-green btn-block"
              startIcon={<TbSend />}
              sx={{ mt: 3 }}
            >
              Uitnodiging Sturen
            </Button>
          </div>
        </Modal>
      </div>
    </Container>
  );
}

export default UserTableView;
