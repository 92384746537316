import React, { useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Button, Container } from '@mui/material';
import { IconCheck } from '@tabler/icons-react';
import Swal from 'sweetalert2';
import { PASSWORD_RESET_CONFIRM_URL } from '../../Constants/URLS';
import './PasswordResetConfirm.scss';
import apiUtils from '../../Utils/ApiUtils';

function PasswordResetConfirm() {
  const { uidb64, token } = useParams();
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const navigate = useNavigate();
  const api = apiUtils();

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!newPassword || !confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Oeps...',
        text: 'Vul alstublieft alle velden in.',
      });
      return;
    }
    if (newPassword !== confirmPassword) {
      Swal.fire({
        icon: 'error',
        title: 'Oeps...',
        text: 'Wachtwoorden komen niet overeen.',
      });
      return;
    }
    api.postNoAuth(PASSWORD_RESET_CONFIRM_URL, {
      uid: uidb64,
      token,
      new_password1: newPassword,
      new_password2: confirmPassword,
    }).then(() => {
      Swal.fire({
        icon: 'success',
        title: 'Gelukt!',
        text: 'Uw wachtwoord is bijgewerkt.',
      }).then(() => {
        navigate('/');
      });
    }).catch((error) => {
      Swal.fire({
        icon: 'error',
        title: 'Oeps...',
        text: error.response.data.detail,
      });
    });
  };

  return (
    <div className="authentication-view">
      <Container maxWidth="lg" sx={{ pt: 5, pb: 15 }}>
        <a href="/">
          <img src="/default-platform-logo.png" alt="logo" className="logo" />
        </a>
      </Container>
      <Container maxWidth="sm" sx={{ mb: 9 }}>
        <div className="panel login-form">
          <h1>
            <span>Wachtwoord</span>
            {' '}
            Opnieuw Instellen
          </h1>
          <p>
            Typ uw nieuwe wachtwoord om het bij te werken.
          </p>
          <form onSubmit={handleSubmit}>
            <label>
              Nieuwe Wachtwoord
              <span className="required">*</span>
            </label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              placeholder="New Password"
            />
            <label>
              Bevestig Nieuwe Wachtwoord
              <span className="required">*</span>
            </label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              placeholder="Confirm New Password"
            />
            <Button
              type="submit"
              className="btn btn-green"
              startIcon={<IconCheck />}
              sx={{ mt: 3 }}
            >
              Reset Password
            </Button>
          </form>
        </div>
      </Container>
    </div>
  );
}

export default PasswordResetConfirm;
