import React from 'react';
import { Container } from '@mui/material';
import { Link } from 'react-router-dom';
import { isProduction } from '../../Utils/EnvUtils';
import BACKOFFICE_MENU from '../../Constants/backoffice_menu';

function BackofficeNavbar() {
  const prodFilter = ['Chatbot'];
  // use filte to remove items from backoffice menu
  let filteredMenu = BACKOFFICE_MENU;
  if (isProduction()) {
    filteredMenu = BACKOFFICE_MENU.filter((item) => !prodFilter.includes(item.title));
  }

  return (
    <div className="backoffice-navbar">
      <Container maxWidth="lg">
        <div className="backoffice-navbar-menu">
          {filteredMenu.map((menuItem, index) => (
            <React.Fragment key={index}>
              {menuItem.children ? (
                <div className="backoffice-navbar-menu-item">
                  {menuItem.icon}
                  {menuItem.title}
                  <div className="backoffice-navbar-menu-children">
                    {menuItem.children.map((child, idx) => (
                      <Link key={`child${idx}`} to={`/backoffice${child.link}`} className="backoffice-navbar-menu-child">
                        {child.icon}
                        {child.title}
                      </Link>
                    ))}
                  </div>
                </div>
              ) : (
                <Link to={`/backoffice${menuItem.link}`} className="backoffice-navbar-menu-item">
                  {menuItem.icon}
                  {menuItem.title}
                </Link>
              )}
            </React.Fragment>
          ))}
        </div>
      </Container>
    </div>
  );
}

export default BackofficeNavbar;
