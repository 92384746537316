import React, { useState, useContext } from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Button, Modal, Box, Typography, TextField, IconButton,
} from '@mui/material';
import { Close } from '@mui/icons-material';
import {
  TbMoodSad, TbMoodNeutral, TbMoodHappy, TbMessage2Exclamation,
} from 'react-icons/tb';
import './FeedbackModal.scss';
import Swal from 'sweetalert2';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import { FEEDBACK_URL } from '../../Constants/URLS';

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 600,
  bgcolor: 'background.paper',
  boxShadow: 24,
  borderRadius: '18px',
  overflow: 'hidden',
};

function FeedbackModal({ pages }) {
  const userContext = useContext(UserContext);
  const utils = apiUtilsHook(userContext);
  const location = useLocation();
  const [open, setOpen] = useState(false);
  const [feedbackScore, setFeedbackScore] = useState(null);
  const [feedbackText, setFeedbackText] = useState('');
  const [warningMessage, setWarningMessage] = useState('');
  const [faceWarning, setFaceWarning] = useState('*Klik op één van de bovenstaande icoontjes');

  const handleOpen = () => setOpen(true);

  const handleClose = () => {
    setFeedbackScore(null);
    setFeedbackText('');
    setWarningMessage('');
    setFaceWarning('*Klik op één van de bovenstaande icoontjes');
    setOpen(false);
  };

  const handleTextChange = (e) => {
    const { value } = e.target;
    setFeedbackText(value);
  };

  const handleFaceClick = (score) => {
    setFeedbackScore(score);
    setFaceWarning('');
  };

  const submitFeedback = async () => {
    try {
      const postData = {
        feedback_page: location.pathname,
        feedback_text: feedbackText,
        feedback_score: feedbackScore,
      };
      const response = await utils.post(FEEDBACK_URL, postData);
      if (response && response.status === 201) {
        Swal.fire({
          title: 'Gelukt',
          text: 'Bericht verzonden',
          icon: 'success',
          confirmButtonText: 'OK',
        });
      }
    } catch (error) {
      // eslint-disable-next-line no-console
      console.error('Error submitting feedback:', error);
    }
  };

  const handleSubmit = async () => {
    if (feedbackScore) {
      await submitFeedback();
      handleClose();
    } else if (!feedbackScore) {
      setFaceWarning('Please click a feedback value above to submit.');
    }
  };

  const shouldRenderFeedback = Object.values(pages).some(
    (page) => page.loginNeeded && location.pathname === page.path,
  );

  const chatbotPage = Object.values(pages).find(
    (page) => location.pathname === page.path && page.showChatbot,
  );

  if (!shouldRenderFeedback) {
    return null;
  }

  return (
    <div className={`feedback-container ${chatbotPage ? 'chat' : ''}`}>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpen}
        className="btn btn-green"
        startIcon={<TbMessage2Exclamation />}
      >
        Feedback
      </Button>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="feedback-modal-title"
        aria-describedby="feedback-modal-description"
      >
        <Box sx={style}>
          <Box sx={{
            backgroundColor: '#BAD60A', padding: '12px 0 6px 0', position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center',
          }}
          >
            <Typography
              id="feedback-modal-title"
              variant="h6"
              component="h2"
              sx={{
                fontFamily: '"Frutiger", sans-serif',
                fontSize: '1.5rem',
                fontWeight: 'bold',
                color: '#fff',
                textAlign: 'center',
                padding: '8px',
              }}
            >
              Feedback achterlaten
            </Typography>
            <IconButton
              sx={{
                position: 'absolute',
                top: '8px',
                right: '8px',
                color: '#fff',
              }}
              onClick={handleClose}
            >
              <Close />
            </IconButton>
          </Box>
          <Box sx={{ padding: '24px', backgroundColor: '#fff' }}>
            <Typography
              sx={{
                textAlign: 'center',
                mb: 2,
                fontFamily: '"Frutiger", sans-serif',
                fontSize: '1rem',
                color: '#606060',
              }}
            >
              Bedankt voor je feedback
            </Typography>
            <Box sx={{
              display: 'flex', justifyContent: 'center', alignItems: 'center', mt: 2, gap: 4,
            }}
            >
              <Box sx={{ textAlign: 'center' }}>
                <IconButton onClick={() => handleFaceClick(1)}>
                  <TbMoodSad size={40} color={feedbackScore === 1 ? 'red' : 'inherit'} />
                </IconButton>
                <Typography variant="caption" sx={{ display: 'block', fontFamily: 'Frutiger, sans-serif' }}>Ontevreden</Typography>
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <IconButton onClick={() => handleFaceClick(2)}>
                  <TbMoodNeutral size={40} color={feedbackScore === 2 ? 'orange' : 'inherit'} />
                </IconButton>
                <Typography variant="caption" sx={{ display: 'block', fontFamily: 'Frutiger, sans-serif' }}>Neutraal</Typography>
              </Box>
              <Box sx={{ textAlign: 'center' }}>
                <IconButton onClick={() => handleFaceClick(3)}>
                  <TbMoodHappy size={40} color={feedbackScore === 3 ? 'green' : 'inherit'} />
                </IconButton>
                <Typography variant="caption" sx={{ display: 'block', fontFamily: 'Frutiger, sans-serif' }}>Tevreden</Typography>
              </Box>
            </Box>
            {faceWarning && (
              <Typography sx={{
                color: 'red', mt: 1, fontSize: '0.875rem', fontWeight: '100', textAlign: 'center', fontFamily: 'Frutiger, sans-serif',
              }}
              >
                {faceWarning}
              </Typography>
            )}
            <TextField
              id="feedback-text"
              multiline
              rows={8}
              fullWidth
              value={feedbackText}
              onChange={handleTextChange}
              placeholder="Typ hier jouw feedback..."
              fontFamily="Frutiger, sans-serif"
              variant="standard"
              InputProps={{
                disableUnderline: true,
                sx: {
                  padding: '0px',
                  '& textarea': {
                    fontFamily: 'Frutiger, sans-serif',
                    padding: '14px',
                    border: 'none',
                    backgroundColor: 'transparent',
                    width: '100%',
                    height: '100%',
                    resize: 'none',
                    borderRadius: '12px',
                  },
                },
              }}
              sx={{
                mt: 2,
                backgroundColor: 'transparent',
              }}
            />
            {warningMessage && (
              <Typography sx={{
                color: 'red', mt: 1, fontSize: '0.875rem', fontWeight: '100', textAlign: 'right',
              }}
              >
                {warningMessage}
              </Typography>
            )}
            <Box sx={{
              display: 'flex', justifyContent: 'space-between', fontFamily: 'Frutiger, sans-serif', gap: 2, mt: 2,
            }}
            >
              <Button
                className="btn btn-green custom-disabled-button"
                onClick={handleSubmit}
                fullWidth
                disabled={!feedbackScore}
                sx={{
                  backgroundColor: '#BAD60A',
                  '&.Mui-disabled': {
                    backgroundColor: '#A8BF08',
                    pointerEvents: 'all',
                  },
                }}
              >
                Verzenden
              </Button>
              <Button
                className="btn btn-grey"
                onClick={handleClose}
                fullWidth
              >
                Annuleren
              </Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

FeedbackModal.propTypes = {
  pages: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      path: PropTypes.string.isRequired,
      showChatbot: PropTypes.bool,
    }),
  ).isRequired,
};

export default FeedbackModal;
