import React, {
  useState, useEffect, useContext,
} from 'react';
import moment from 'moment';
import { Masonry } from '@mui/lab';
import {
  Container, Button,
} from '@mui/material';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import {
  IconArrowLeft,
  IconArrowRight,
  IconDownload,
  IconHelpSquareRounded,
  IconMessages,
  IconTags,
  IconUserCircle,
} from '@tabler/icons-react';
import Panel from '../../../Components/Panel/Panel';
import apiUtilsHook from '../../../Utils/ApiUtilsHook';
import { UserContext } from '../../../Providers/UserProvider/UserProvider';
import './Overview.scss';
import AccessDeniedMessage from '../../../Components/AccessDeniedMessage';
import jsonToCSV from '../../../Components/JsonToCSV/JsonToCSV';
import LoadingScreen from '../../LoadingScreen';
import {
  FAQ_URL,
  MESSAGE_PER_USER_COUNTS_URL,
  SOURCES_COUNTS_URL,
  MESSAGE_BY_TIME_COUNTS_URL,
  MESSAGES_PER_TOPIC_URL,
} from '../../../Constants/URLS';
import FormInput from '../../../Components/Backoffice/FormInput';

function DataDashboardView() {
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const [questionSearchQuery, setQuestionSearchQuery] = useState('');
  const [userSearchQuery, setUserSearchQuery] = useState('');

  // Define the roles that have access to this view
  const allowedRoles = ['Staff', 'Admin'];
  const hasAccess = allowedRoles.includes(userContext.user.role);

  // Get the current date
  const currentDate = new Date();
  const latestYear = currentDate.getFullYear();
  const latestMonth = String(currentDate.getMonth() + 1).padStart(2, '0');
  const [period, setPeriod] = useState('monthly');
  const [date, setDate] = useState(`${latestYear}-${latestMonth}`);
  const [dateFrom, setDateFrom] = useState('');
  const [dateTo, setDateTo] = useState('');

  // States for User Panel
  const [faqsData, setFaqsData] = useState([]);
  const [activeUsersData, setActiveUsersData] = useState([]);
  const [topicData, setTopicData] = useState([]);
  const [topicMessagesData, setTopicMessagesData] = useState([]);
  const [messageCountsData, setMessageCountsData] = useState([]);
  const [pageSize] = useState(5);

  // Data download function
  const downloadDataAsZip = () => {
    const zip = new JSZip();
    let periodString = period === 'custom' ? `${dateFrom}_${dateTo}` : date;
    if (period === 'all') {
      periodString = 'Altijd';
    }
    // Convert JSON data to CSV and add to the zip file
    zip.file(`AOP_ActieveGebruikers_${periodString}.csv`, jsonToCSV(faqsData));
    zip.file(`AOP_MeestActieveGebruikers_${periodString}.csv`, jsonToCSV(activeUsersData));
    zip.file(`AOP_Onderwerpen_${periodString}.csv`, jsonToCSV(topicData));
    zip.file(`AOP_Berichten_${periodString}.csv`, jsonToCSV(messageCountsData));
    zip.file(`AOP_TopicMessages_${periodString}.csv`, jsonToCSV(topicMessagesData));
    // Generate the zip file and trigger the download
    zip.generateAsync({ type: 'blob' }).then((content) => {
      saveAs(content, `AOP_Data_${periodString}.zip`);
    });
  };

  const handlePeriodChange = (event) => {
    const newPeriod = event.target.value;
    setPeriod(newPeriod);

    if (newPeriod === 'custom') {
      // Set default custom range to the current month
      const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
      const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');
      setDateFrom(startOfMonth);
      setDateTo(endOfMonth);
    } else if (newPeriod !== 'all') {
      // Adjust 'date' for non-custom, non-all periods
      const newDate = newPeriod === 'monthly' ? moment().format('YYYY-MM') : moment().format('YYYY');
      setDate(newDate);
    }
  };

  const navigateDate = (direction) => {
    const format = period === 'monthly' ? 'YYYY-MM' : 'YYYY';
    const unit = period === 'monthly' ? 'months' : 'years';
    setDate(moment(date, format).add(direction, unit).format(format));
  };
  // Fetch Frequently Asked Questions
  const fetchFAQs = async (page = 1) => {
    try {
      const params = {
        period, page, page_size: pageSize,
      };
      if (period === 'custom') {
        params.date_from = dateFrom;
        params.date_to = dateTo;
      } else if (period !== 'all') {
        params.date = date;
      }
      if (questionSearchQuery) {
        params.search = questionSearchQuery;
      }
      const queryString = new URLSearchParams(params).toString();
      const response = await api.get(`${FAQ_URL}?${queryString}`);
      setFaqsData(response.data.results);
    } catch (error) {
      console.error('Error fetching FAQs:', error);
    }
  };

  const fetchActiveUsers = async (page = 1) => {
    try {
      const params = {
        period, page, page_size: pageSize,
      };
      if (period === 'custom') {
        params.date_from = dateFrom;
        params.date_to = dateTo;
      } else if (period !== 'all') {
        params.date = date;
      }
      if (userSearchQuery) {
        params.search = userSearchQuery;
      }
      const queryString = new URLSearchParams(params).toString();
      const response = await api.get(`${MESSAGE_PER_USER_COUNTS_URL}?${queryString}`);
      setActiveUsersData(response.data);
    } catch (error) {
      console.error('Error fetching active users:', error);
    }
  };

  const fetchSources = async (page = 1) => {
    try {
      const params = { period, page, page_size: pageSize };
      if (period === 'custom') {
        params.date_from = dateFrom;
        params.date_to = dateTo;
      } else if (period !== 'all') {
        params.date = date;
      }
      const queryString = new URLSearchParams(params).toString();
      const response = await api.get(`${SOURCES_COUNTS_URL}?${queryString}`);
      const convertedData = response.data.results.map((item) => ({
        category: item.topics__name?.length > 16 ? `${item.topics__name.slice(0, 13)}...` : item.topics__name,
        value: item.count,
      }));
      setTopicData(convertedData);
    } catch (error) {
      console.error('Error fetching sources:', error);
    }
  };

  const fetchMessageCounts = async (page = 1) => {
    try {
      const params = { period, page, page_size: pageSize };
      if (period === 'custom') {
        params.date_from = dateFrom;
        params.date_to = dateTo;
      } else if (period !== 'all') {
        params.date = date;
      }
      const queryString = new URLSearchParams(params).toString();
      const response = await api.get(`${MESSAGE_BY_TIME_COUNTS_URL}?${queryString}`);
      const formattedData = response.data.map((item) => ({
        ...item,
        date: new Date(item.date).toISOString().split('T')[0],
      }));
      setMessageCountsData(formattedData);
    } catch (error) {
      console.error('Error fetching message counts:', error);
    }
  };

  const fetchMessagesByTopic = async (page = 1) => {
    try {
      const params = { period, page, page_size: pageSize };
      if (period === 'custom') {
        params.date_from = dateFrom;
        params.date_to = dateTo;
      } else if (period !== 'all') {
        params.date = date;
      }
      const queryString = new URLSearchParams(params).toString();
      const response = await api.get(`${MESSAGES_PER_TOPIC_URL}?${queryString}`);
      const convertedData = response.data.map((item) => ({
        category: item.sources__topics__name?.length > 16 ? `${item.sources__topics__name.slice(0, 13)}...` : item.sources__topics__name,
        value: item.count,
      }));
      setTopicMessagesData(convertedData);
    } catch (error) {
      console.error('Error fetching messages by topic:', error);
    }
  };

  useEffect(() => {
    fetchFAQs();
  }, [questionSearchQuery]);

  useEffect(() => {
    fetchActiveUsers();
  }, [userSearchQuery]);

  // Fetch data for Active Users
  useEffect(() => {
    fetchActiveUsers();
    fetchFAQs();
    fetchSources();
    fetchMessageCounts();
    fetchMessagesByTopic();
  }, [period, date, dateFrom, dateTo]);
  // Loading condition
  if (!faqsData || !activeUsersData || !topicData) {
    return <LoadingScreen />;
  }

  if (userContext.user && userContext.user.first_name && !hasAccess) {
    return <AccessDeniedMessage />;
  }

  return (
    <Container maxWidth="lg" sx={{ pt: 6, pb: 9 }}>
      <div className="chat-dashboard-view">
        <div className="heading-container">
          <div className="time-controls">
            <select className="period" value={period} onChange={handlePeriodChange}>
              <option value="monthly">Maand</option>
              <option value="yearly">Jaar</option>
              <option value="all">Altijd</option>
              <option value="custom">Anders</option>
            </select>
            {period === 'custom' && (
            <>
              <input
                type="date"
                value={dateFrom}
                onChange={(e) => setDateFrom(e.target.value)}
                className="period-date"
              />
              <input
                type="date"
                value={dateTo}
                onChange={(e) => setDateTo(e.target.value)}
                className="period-date"
              />
            </>
            )}
            {period !== 'all' && period !== 'custom' && (
            <div className="period-control">
              <Button
                onClick={() => navigateDate(-1)}
                className="btn btn-icon btn-green"
              >
                <IconArrowLeft />
              </Button>
              <span>{date}</span>
              <Button
                onClick={() => navigateDate(1)}
                className="btn btn-icon btn-green"
              >
                <IconArrowRight />
              </Button>
            </div>
            )}
          </div>
          <Button
            className="btn btn-green"
            onClick={downloadDataAsZip}
            startIcon={<IconDownload />}
          >
            Gegevens Downloaden
          </Button>
        </div>
        <div className="dashboard-container">
          <Masonry columns={2} spacing={6}>
            <Panel
              title="Voorgestelde vragen"
              icon={<IconHelpSquareRounded />}
              datasets={[
                {
                  data: faqsData,
                  type: 'table',
                  period,
                  setPeriod,
                  date,
                  setDate,
                  columns: [
                    { key: 'question', header: 'Vraag' },
                    { key: 'used', header: 'Aantal' },
                  ],
                  secondary: (
                    <FormInput
                      onChange={setQuestionSearchQuery}
                      value={questionSearchQuery}
                      placeholder="Zoeken..."
                    />
                  ),
                },
              ]}
            />
            <Panel
              title="Meest actieve gebruikers"
              icon={<IconUserCircle />}
              datasets={[
                {
                  data: activeUsersData,
                  type: 'table',
                  period,
                  setPeriod,
                  date,
                  setDate,
                  columns: [
                    { key: 'full_name', header: 'Gebruiker' },
                    { key: 'count', header: 'Aantal Berichten' },
                  ],
                  secondary: (
                    <FormInput
                      onChange={setUserSearchQuery}
                      value={userSearchQuery}
                      placeholder="Zoeken..."
                    />
                  ),
                },
              ]}
            />
            <Panel
              title="Onderwerpen"
              icon={<IconTags />}
              datasets={[
                {
                  data: topicMessagesData,
                  type: 'bar',
                  graphTitle: 'Berichten per onderwerp',
                  yAxisLabel: 'Aantal berichten',
                  period,
                  setPeriod,
                  date,
                  setDate,
                },
                {
                  data: topicData,
                  type: 'bar',
                  graphTitle: 'Bronnen per onderwerp',
                  yAxisLabel: 'Aantal',
                  period,
                  setPeriod,
                  date,
                  setDate,
                },
              ]}
            />
            <Panel
              title="Hoeveelheid Berichten"
              icon={<IconMessages />}
              datasets={[
                {
                  data: messageCountsData,
                  type: 'line',
                  graphTitle: 'Aantal gematchte berichten',
                  yAxisLabel: 'Aantal',
                  period,
                  setPeriod,
                  date,
                  setDate,
                  xAxisKey: 'time',
                  yAxisKey: 'count',
                },
              ]}
            />
          </Masonry>
        </div>
      </div>
    </Container>
  );
}

export default DataDashboardView;
