import { Popover } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';
import Checkbox from '../Checkbox';

function OptionsPopover({
  anchorEl, open, closeMenu, options, value, onChange, search, onSearchChange, count,
}) {
  return (
    <Popover
      open={open}
      anchorEl={anchorEl}
      onClose={closeMenu}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
      className="options-popover"
    >
      {onSearchChange && (
        <input
          type="text"
          value={search}
          onChange={(e) => onSearchChange(e.target.value)}
          placeholder="Zoek..."
          className="options-popover-search"
        />
      )}
      {options.length === 0 && (
        <small>Geen opties</small>
      )}
      {onSearchChange && count > options.length && (
        <small>Zoek voor meer opties</small>
      )}
      {options.map((option, index) => (
        <Checkbox
          id={`checkbox${index}`}
          checked={value.includes(option.value)}
          onChange={() => onChange(option.value)}
          key={option.value}
        >
          {option.icon}
          {option.text}
        </Checkbox>
      ))}
    </Popover>
  );
}

OptionsPopover.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]).isRequired,
    icon: PropTypes.node,
  })).isRequired,
  value: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ])).isRequired,
  onChange: PropTypes.func.isRequired,
  search: PropTypes.string,
  onSearchChange: PropTypes.func,
  count: PropTypes.number,
  anchorEl: PropTypes.element,
  open: PropTypes.bool.isRequired,
  closeMenu: PropTypes.func.isRequired,
};

OptionsPopover.defaultProps = {
  search: '',
  onSearchChange: null,
  count: 0,
  anchorEl: null,
};

export default OptionsPopover;
