import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Navigate } from 'react-router-dom';
import { Box, CircularProgress } from '@mui/material';
import { UserContext } from '../../Providers/UserProvider/UserProvider';

function AuthenticatedRoute({ element, roles }) {
  const { user, tokens } = useContext(UserContext);

  if (!user.email) {
    return (
      <Box className="loader">
        <CircularProgress />
      </Box>
    );
  }

  if (roles?.length > 0 && !roles.includes(user.role)) {
    return (
      <Navigate
        to={{
          pathname: '/home',
        }}
      />
    );
  }

  return tokens?.accessToken?.length > 0 ? element : (
    <Navigate
      to={{
        pathname: '/login',
      }}
    />
  );
}

AuthenticatedRoute.propTypes = {
  element: PropTypes.element.isRequired,
  roles: PropTypes.arrayOf(PropTypes.string),
};

AuthenticatedRoute.defaultProps = {
  roles: [],
};

export default AuthenticatedRoute;
