import React from 'react';
import { Container } from '@mui/material';
import PropTypes from 'prop-types';

function Footer({ pages }) {
  const path = window.location.pathname;

  return (
    <footer id="footer">
      <Container maxWidth={path === '/' ? 'xl' : 'lg'}>
        <div className="footer-content">
          <a className="footer-logo" href="https://www.creditlife.nl/" target="_blank" rel="noreferrer">
            <img src="/logo-color.png" alt="logo" />
          </a>
          <a className="footer-link footer-link-1" href={pages.terms_and_conditions.path}>Gebruikersvoorwaarden</a>
          <a className="footer-link footer-link-2" href={pages.privacy_policy.path}>Privacyverklaring</a>
        </div>
      </Container>
    </footer>
  );
}

Footer.propTypes = {
  pages: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      path: PropTypes.string.isRequired,
      showInMenu: PropTypes.bool,
      showMenu: PropTypes.bool,
      navName: PropTypes.string,
      menuIcon: PropTypes.element,
      roles: PropTypes.arrayOf(PropTypes.string),
    }),
  ).isRequired,
};

export default Footer;
