import React from 'react';
import {
  TbHourglass, TbSmartHome, TbChartHistogram, TbUsers, TbFolders, TbTags,
  TbFolderPlus, TbMessage2Exclamation, TbMessages, TbBook,
} from 'react-icons/tb';
import { IconZoom } from '@tabler/icons-react';
import ClientForm from '../Views/ClientForm';
import SignupView from '../Views/SignUpView';
import BestMatchesView from '../Views/BestMatchesView';
import HomePage from '../Views/HomePage';
import LandingView from '../Views/LandingView';
import LoginView from '../Views/Login';
import ProfileSettingsView from '../Views/ProfileSettingsView';
import DataDashboardView from '../Views/DataDashboardView';
import UserTableView from '../Views/UserTableView/UserTableView';
import PrivacyPolicy from '../Views/PrivacyPolicy';
import TermsAndConditions from '../Views/TermsAndConditions';
import FeedbackTable from '../Views/FeedbackView/FeedbackView';
import CaseContentDashboard from '../Views/CaseContentDashboard';
import CaseStudyGenerator from '../Views/CaseStudyGenerator';
import ChatView from '../Views/ChatView';
import SourcesTableView from '../Views/ChatBackoffice/Sources/SourcesTableView';
import TopicsTableView from '../Views/ChatBackoffice/Topics/TopicsTableView';
import TopicAddView from '../Views/ChatBackoffice/Topics/TopicAddView';
import TopicEditView from '../Views/ChatBackoffice/Topics/TopicEditView';
import Overview from '../Views/ChatBackoffice/Overview';
import ConversationTableView from '../Views/ChatBackoffice/Conversations/ConversationTableView';
import PasswordResetConfirm from '../Views/PasswordResetConfirm';
import ConfirmEmail from '../Views/ConfirmEmail';
import LegalTexts from '../Views/LegalTexts';
import ConversationView from '../Views/ChatBackoffice/Conversations/ConversationView';
import SourceNewView from '../Views/ChatBackoffice/Sources/SourceNewView';
import SourceView from '../Views/ChatBackoffice/Sources/SourceView';
import FAQTableView from '../Views/ChatBackoffice/FAQ/FAQTableView';
import MatchesDetailView from '../Views/MatchesDetailView';
import FAQView from '../Views/ChatBackoffice/FAQ/FAQView';
import AdditionalTextView from '../Components/AdditionalTextView';

export default function Pages() {
  return {
    landing: {
      id: 1,
      path: '/',
      loginNeeded: false,
      showMenu: false,
      element: <LandingView />,
    },
    home: {
      id: 2,
      menuIcon: <TbSmartHome />,
      showInMenu: true,
      path: '/home',
      loginNeeded: true,
      showChatbot: true,
      showMenu: true,
      navName: 'Home',
      element: <HomePage />,
    },
    signup: {
      id: 3,
      path: '/signup',
      loginNeeded: false,
      showMenu: false,
      element: <SignupView />,
    },
    login: {
      id: 4,
      showMenu: false,
      loginNeeded: false,
      path: '/login',
      element: <LoginView />,
    },
    client_form: {
      id: 5,
      menuIcon: <IconZoom />,
      showInMenu: true,
      showMenu: true,
      loginNeeded: true,
      showChatbot: true,
      navName: 'Vind een risicomatch',
      path: '/client-form',
      element: <ClientForm />,
    },
    best_matches: {
      id: 6,
      menuIcon: <TbHourglass />,
      showInMenu: false,
      showMenu: true,
      loginNeeded: true,
      showChatbot: true,
      navName: 'Geschiedenis',
      path: '/matches',
      element: <BestMatchesView />,
    },
    profile: {
      id: 7,
      showMenu: true,
      loginNeeded: true,
      showChatbot: true,
      navName: 'Profiel',
      path: '/profile',
      element: <ProfileSettingsView />,
    },
    data_dashboard: {
      id: 8,
      showMenu: true,
      loginNeeded: true,
      showChatbot: true,
      menuIcon: <TbChartHistogram />,
      navName: 'Data dashboard',
      path: '/backoffice/data-dashboard',
      roles: ['Admin', 'Staff'],
      element: <DataDashboardView />,
    },
    user_table: {
      id: 9,
      showMenu: true,
      loginNeeded: true,
      showChatbot: true,
      menuIcon: <TbUsers />,
      navName: 'Gebruikers',
      path: '/backoffice/user-table',
      roles: ['Admin', 'Staff'],
      element: <UserTableView />,
    },
    privacy_policy: {
      id: 10,
      showMenu: true,
      loginNeeded: false,
      showChatbot: false,
      path: '/privacyverklaring',
      element: <PrivacyPolicy />,
    },
    terms_and_conditions: {
      id: 11,
      showMenu: true,
      loginNeeded: false,
      showChatbot: false,
      path: '/gebruikersvoorwaarden',
      element: <TermsAndConditions />,
    },
    cases: {
      id: 12,
      showMenu: true,
      loginNeeded: true,
      showChatbot: true,
      path: '/backoffice/cases',
      element: <CaseContentDashboard />,
      roles: ['Admin', 'Staff'],
      navName: 'Case beheer',
      menuIcon: <TbFolders />,
    },
    case_content_generator: {
      id: 13,
      showMenu: true,
      loginNeeded: true,
      showChatbot: true,
      path: '/backoffice/case-content-generator',
      element: <CaseStudyGenerator />,
      roles: ['Admin', 'Staff'],
      navName: 'Case generator',
      menuIcon: <TbFolderPlus />,
    },
    feedback: {
      id: 14,
      showMenu: true,
      loginNeeded: true,
      showChatbot: true,
      path: '/backoffice/feedback',
      element: <FeedbackTable />,
      roles: ['Admin', 'Staff'],
      navName: 'Feedback',
      menuIcon: <TbMessage2Exclamation />,
    },
    chat: {
      id: 15,
      showInMenu: true,
      showMenu: true,
      loginNeeded: true,
      showChatbot: false,
      path: '/chat',
      element: <ChatView />,
      navName: 'Chat',
      menuIcon: <TbMessages />,
    },
    chatWithId: {
      id: 16,
      showMenu: true,
      loginNeeded: true,
      showChatbot: false,
      path: '/chat/:conversationId',
      element: <ChatView />,
    },
    sources_table: {
      id: 17,
      showMenu: true,
      loginNeeded: true,
      showChatbot: true,
      path: '/backoffice/sources',
      element: <SourcesTableView />,
      roles: ['Admin', 'Staff'],
      navName: 'Bronnen',
      menuIcon: <TbBook />,
    },
    topics_table: {
      id: 18,
      showMenu: true,
      loginNeeded: true,
      showChatbot: true,
      path: '/backoffice/topics',
      element: <TopicsTableView />,
      roles: ['Admin', 'Staff'],
      navName: 'Onderwerpen',
      menuIcon: <TbTags />,
    },
    add_topic: {
      id: 19,
      showMenu: false,
      loginNeeded: true,
      showChatbot: true,
      path: '/backoffice/topics/add',
      element: <TopicAddView />,
      roles: ['Admin', 'Staff'],
    },
    new_source: {
      id: 20,
      showMenu: false,
      loginNeeded: true,
      showChatbot: true,
      path: '/backoffice/sources/new',
      element: <SourceNewView />,
      roles: ['Admin', 'Staff'],
    },
    edit_topic: {
      id: 21,
      showMenu: false,
      loginNeeded: true,
      showChatbot: true,
      path: '/backoffice/topics/:id',
      element: <TopicEditView />,
      roles: ['Admin', 'Staff'],
    },
    source: {
      id: 22,
      showMenu: false,
      loginNeeded: true,
      showChatbot: true,
      path: '/backoffice/sources/:id',
      element: <SourceView />,
      roles: ['Admin', 'Staff'],
    },
    password_reset_confirm: {
      id: 23,
      showMenu: false,
      loginNeeded: false,
      showChatbot: false,
      path: '/reset-password/:uidb64/:token',
      element: <PasswordResetConfirm />,
    },
    confirm_email: {
      id: 24,
      showMenu: false,
      loginNeeded: false,
      showChatbot: false,
      path: '/confirm-email/:key',
      element: <ConfirmEmail />,
    },
    conversations_table: {
      id: 25,
      showMenu: true,
      loginNeeded: true,
      showChatbot: true,
      path: '/backoffice/conversations',
      element: <ConversationTableView />,
      roles: ['Admin', 'Staff'],
      navName: 'Gesprekken',
      menuIcon: <TbMessages />,
    },
    conversation_view: {
      id: 26,
      showMenu: true,
      loginNeeded: true,
      showChatbot: false,
      path: '/backoffice/conversation/:id',
      element: <ConversationView />,
      roles: ['Admin', 'Staff'],
    },
    legal_texts: {
      id: 27,
      showMenu: true,
      loginNeeded: true,
      path: '/backoffice/legal-texts',
      element: <LegalTexts />,
      roles: ['Admin'],
      navName: 'Legal texts',
    },
    faq_management: {
      id: 28,
      showMenu: true,
      loginNeeded: true,
      showChatbot: true,
      path: '/backoffice/faq',
      element: <FAQTableView />,
      roles: ['Admin', 'Staff'],
      navName: 'FAQ',
    },
    client_match: {
      id: 29,
      showMenu: true,
      loginNeeded: true,
      showChatbot: true,
      element: <MatchesDetailView />,
      path: '/client-match',
    },
    chat_overview: {
      id: 30,
      showMenu: true,
      loginNeeded: true,
      showChatbot: true,
      path: '/backoffice/chat-overview',
      element: <Overview />,
      navName: 'Overzicht',
      roles: ['Admin', 'Staff'],
    },
    single_faq: {
      id: 31,
      showMenu: true,
      loginNeeded: true,
      showChatbot: true,
      path: '/backoffice/faq/:id',
      element: <FAQView />,
    },
    additional_text: {
      id: 32,
      path: '/additional-text/:id',
      element: <AdditionalTextView />,
      loginNeeded: true,
      showChatbot: false,
      showInMenu: false,
      showMenu: false,
    },
  };
}
