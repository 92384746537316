import React, { useState, useEffect, useContext } from 'react';
import {
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, TablePagination,
  TableSortLabel, Container, Box,
} from '@mui/material';
import { IconAlertTriangleFilled, IconMessages } from '@tabler/icons-react';
import { useNavigate } from 'react-router-dom';
import { UserContext } from '../../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../../Utils/ApiUtilsHook';
import { CONVERSATIONS_URL } from '../../../../Constants/URLS';
import {
  containerStyles, tablePaginationStyles,
} from '../../../../Components/TableStyles/TableStyles';
import './ConversationTableView.scss';
import BackofficeTitle from '../../../../Components/Backoffice/BackofficeTitle';
import TableSelectLabel from '../../../../Components/Backoffice/TableSelectLabel';

function ConversationTableView() {
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const [conversations, setConversations] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('created_at');
  const [search, setSearch] = useState('');
  const [totalCount, setTotalCount] = useState(0);
  const navigate = useNavigate();

  const alertOptions = [
    {
      text: 'Ja',
      value: true,
    },
    {
      text: 'Nee',
      value: false,
    },
  ];

  const [alertFilter, setAlertFilter] = useState([]);

  const fetchConversations = async () => {
    const response = await api.get(CONVERSATIONS_URL, {
      params: {
        page,
        page_size: rowsPerPage,
        ordering: `${order === 'desc' ? '-' : ''}${orderBy}`,
        search,
        has_escalation: alertFilter[0],
      },
    });
    setConversations(response.data.results);
    console.log('conversations', response.data.results);
    setTotalCount(response.data.count);
  };

  const handleRequestSort = (property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  useEffect(() => {
    if (alertFilter.length === 2) {
      setAlertFilter([]);
    }
  }, [alertFilter]);

  useEffect(() => {
    fetchConversations();
  }, [page, rowsPerPage, order, orderBy, search, alertFilter]);

  return (
    <Container maxWidth="lg" sx={containerStyles}>
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <BackofficeTitle title="Gesprekken" icon={<IconMessages />} />
        <input
          placeholder="Zoek..."
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
            setPage(1);
          }}
          className="backoffice-search"
        />
      </Box>
      <div className="conversations-view">
        <TableContainer>
          <Table>
            <TableHead className="table-header">
              <TableRow>
                <TableCell>
                  <TableSelectLabel
                    onChange={setAlertFilter}
                    options={alertOptions}
                    value={alertFilter}
                  >
                    Waarschuwing
                  </TableSelectLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'user__full_name'}
                    direction={orderBy === 'user__full_name' ? order : 'asc'}
                    onClick={() => handleRequestSort('user__full_name')}
                  >
                    Naam
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'user__email'}
                    direction={orderBy === 'user__email' ? order : 'asc'}
                    onClick={() => handleRequestSort('user__email')}
                  >
                    Email
                  </TableSortLabel>
                </TableCell>
                <TableCell>
                  <TableSortLabel
                    active={orderBy === 'created_at'}
                    direction={orderBy === 'created_at' ? order : 'asc'}
                    onClick={() => handleRequestSort('created_at')}
                  >
                    Datum en tijd
                  </TableSortLabel>
                </TableCell>
                <TableCell className="center">Berichten</TableCell>
                {/* <TableCell className="center">Onderwerpen</TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {conversations.map((conversation) => (
                <TableRow
                  key={conversation.id}
                  className="table-row"
                  onClick={() => navigate(`/backoffice/conversation/${conversation.id}`)}
                >
                  <TableCell>
                    {conversation.escalation && (
                      <IconAlertTriangleFilled className="alert-icon" />
                    )}
                  </TableCell>
                  <TableCell>
                    {conversation.user?.full_name || conversation.user?.email || 'Unknown User'}
                  </TableCell>
                  <TableCell>{conversation.user.email}</TableCell>
                  <TableCell>
                    {new Date(conversation.created_at).toLocaleString([], {
                      year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit',
                    })}
                  </TableCell>
                  <TableCell className="center">
                    {conversation.messages_count}
                  </TableCell>
                  <TableCell className="center">
                    {conversation.topics}
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          component="div"
          count={totalCount}
          page={page - 1}
          onPageChange={(event, newPage) => setPage(newPage + 1)}
          rowsPerPage={rowsPerPage}
          onRowsPerPageChange={(event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(1);
          }}
          labelRowsPerPage="Rijen per pagina:"
          labelDisplayedRows={({ from, to, count }) => `${from}-${to} van ${count !== -1 ? count : `meer dan ${to}`}`}
          style={tablePaginationStyles}
        />
      </div>
    </Container>
  );
}

export default ConversationTableView;
