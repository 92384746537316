import React, { useState } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Button from '@mui/material/Button';
import PropTypes from 'prop-types';
import { TbArrowRight } from 'react-icons/tb';
import './PaginatedTable.scss';

function PaginatedTable({
  data, columns, fetchPage, count, pageSize, includeIconColumn, iconColumnName, onIconClick,
  includeSecondIconColumn, onSecondIconClick, secondIconColumnName, secondIconColumnIcon,
  secondIconColumnClass,
}) {
  const [page, setPage] = useState(1);

  const totalPages = Math.ceil(count / pageSize);

  const handleNextPage = () => {
    if (page < totalPages) {
      setPage((prevPage) => {
        const newPage = prevPage + 1;
        fetchPage(newPage);
        return newPage;
      });
    }
  };

  const handlePreviousPage = () => {
    if (page > 1) {
      setPage((prevPage) => {
        const newPage = prevPage - 1;
        fetchPage(newPage);
        return newPage;
      });
    }
  };

  return (
    <>
      <TableContainer sx={{ mt: 3 }}>
        <Table className="backoffice-table">
          <TableHead>
            <TableRow>
              {columns.map((column) => (
                <TableCell key={column.key} sx={{ fontWeight: 600 }}>{column.header}</TableCell>
              ))}
              {includeSecondIconColumn && (
                <TableCell align="center" sx={{ fontWeight: 600 }}>{secondIconColumnName}</TableCell>
              )}
              {includeIconColumn && (
                <TableCell align="center" sx={{ fontWeight: 600 }}>{iconColumnName}</TableCell>
              )}
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((row, index) => (
              <TableRow
                key={index}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                {columns.map((column) => (
                  <TableCell key={column.key}>{row[column.key]}</TableCell>
                ))}
                {includeSecondIconColumn && (
                  <TableCell align="center">
                    <button
                      type="button"
                      onClick={() => onSecondIconClick(row)}
                      className={`action-button ${secondIconColumnClass}`}
                      aria-label="Knop voor een aangepaste actie"
                    >
                      {secondIconColumnIcon}
                    </button>
                  </TableCell>
                )}
                {includeIconColumn && (
                  <TableCell align="center">
                    <button
                      type="button"
                      onClick={() => onIconClick(row)}
                      className="action-button"
                      aria-label="Knop voor een aangepaste actie"
                    >
                      <TbArrowRight />
                    </button>
                  </TableCell>
                )}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '16px' }}>
        <Button
          onClick={handlePreviousPage}
          disabled={page === 1}
          className={`btn ${page > 1 ? 'btn-green' : 'btn-grey'}`}
          sx={{ mt: 1, mr: 1 }}
        >
          Vorige Pagina
        </Button>
        <Button
          onClick={handleNextPage}
          disabled={page >= totalPages}
          className={`btn ${page < totalPages ? 'btn-green' : 'btn-grey'}`}
          sx={{ mt: 1 }}
        >
          Volgende Pagina
        </Button>
      </div>
    </>
  );
}

PaginatedTable.propTypes = {
  // eslint-disable-next-line
  data: PropTypes.arrayOf(
    PropTypes.object,
  ).isRequired,
  columns: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      header: PropTypes.string.isRequired,
    }),
  ).isRequired,
  fetchPage: PropTypes.func.isRequired,
  count: PropTypes.number.isRequired,
  pageSize: PropTypes.number.isRequired,
  includeIconColumn: PropTypes.bool,
  iconColumnName: PropTypes.string,
  onIconClick: PropTypes.func,
  includeSecondIconColumn: PropTypes.bool,
  secondIconColumnName: PropTypes.string,
  onSecondIconClick: PropTypes.func,
  secondIconColumnIcon: PropTypes.element,
  secondIconColumnClass: PropTypes.string,
};

PaginatedTable.defaultProps = {
  includeIconColumn: false,
  iconColumnName: '',
  onIconClick: () => { },
  includeSecondIconColumn: false,
  secondIconColumnName: '',
  onSecondIconClick: () => { },
  secondIconColumnIcon: null,
  secondIconColumnClass: '',
};

export default PaginatedTable;
