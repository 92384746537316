import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { IconChevronDown, IconFilterFilled } from '@tabler/icons-react';
import OptionsPopover from '../OptionsPopover';

function TableSelectLabel({
  // eslint-disable-next-line
                            options, value, onChange, search, onSearchChange, count, children,
}) {
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const openMenu = (e) => {
    setAnchorEl(e.currentTarget);
  };

  const closeMenu = () => {
    setAnchorEl(false);
  };

  return (
    <>
      {/* eslint-disable-next-line */}
      <div onClick={openMenu} className="table-select-label">
        {children}
        {value.length > 0 && (
          <IconFilterFilled className="pulsating" />
        )}
        <IconChevronDown className="chevron" />
      </div>
      <OptionsPopover
        anchorEl={anchorEl}
        open={open}
        closeMenu={closeMenu}
        options={options}
        value={value}
        onChange={(val) => {
          if (value.includes(val)) {
            onChange(value.filter((v) => v !== val));
          } else {
            onChange([...value, val]);
          }
        }}
        search={search}
        onSearchChange={onSearchChange}
        count={count}
      />
    </>
  );
}

TableSelectLabel.propTypes = {
  options: PropTypes.arrayOf(PropTypes.shape({
    text: PropTypes.string.isRequired,
    value: PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
      PropTypes.bool,
    ]).isRequired,
    icon: PropTypes.node,
  })).isRequired,
  value: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
  ])).isRequired,
  onChange: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  search: PropTypes.string,
  onSearchChange: PropTypes.func,
  count: PropTypes.number,
};

TableSelectLabel.defaultProps = {
  search: '',
  onSearchChange: null,
  count: 0,
};

export default TableSelectLabel;
