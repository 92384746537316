import React from 'react';
import PropTypes from 'prop-types';

function BackofficeTitle({ icon, title }) {
  return (
    <div className="backoffice-title">
      <div className="backoffice-title-icon">
        {icon}
      </div>
      <h1>
        {title}
      </h1>
    </div>
  );
}

BackofficeTitle.propTypes = {
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
};

export default BackofficeTitle;
