import React, { useState, useEffect, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Table, TableBody, TableCell, TableContainer,
  TableHead, TableRow, TablePagination,
  TableSortLabel, Container,
  Button, Box,
} from '@mui/material';
import {
  IconAlertCircleFilled,
  IconBooks,
  IconChecks, IconChevronDown,
  IconLoader,
  IconLoaderQuarter,
  IconUpload,
} from '@tabler/icons-react';
import moment from 'moment';
import Swal2 from 'sweetalert2';
import { UserContext } from '../../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../../Utils/ApiUtilsHook';
import { SOURCES_URL, TOPICS_URL } from '../../../../Constants/URLS';
import {
  containerStyles, tablePaginationStyles,
} from '../../../../Components/TableStyles/TableStyles';
import './SourcesTableView.scss';
import BackofficeTitle from '../../../../Components/Backoffice/BackofficeTitle';
import TableSelectLabel from '../../../../Components/Backoffice/TableSelectLabel';
import StatusSwitch from '../../../../Components/Backoffice/StatusSwitch';
import Checkbox from '../../../../Components/Backoffice/Checkbox';
import OptionsPopover from '../../../../Components/Backoffice/OptionsPopover';

function SourcesTableView() {
  const navigate = useNavigate();
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const [sources, setSources] = useState([]);
  const [page, setPage] = useState(1);
  const [rowsPerPage, setRowsPerPage] = useState(25);
  const [order, setOrder] = useState('desc');
  const [orderBy, setOrderBy] = useState('');
  const [search, setSearch] = useState('');
  const [totalCount, setTotalCount] = useState(0);

  const [selectedSources, setSelectedSources] = useState([]);
  const [bulkAction, setBulkAction] = useState([]);
  const [anchorElBulkAction, setAnchorElBulkAction] = useState(null);
  const openBulkAction = Boolean(anchorElBulkAction);

  const handleSelectAll = () => {
    if (selectedSources.length === sources.length) {
      setSelectedSources([]);
      return;
    }

    setSelectedSources(sources.map((source) => source.id));
  };

  const handleRowSelect = (id) => {
    if (selectedSources.includes(id)) {
      setSelectedSources(selectedSources.filter((sourceId) => sourceId !== id));
    } else {
      setSelectedSources([...selectedSources, id]);
    }
  };

  const onlineOptions = [
    {
      text: 'Online',
      value: 'True',
    },
    {
      text: 'Offline',
      value: 'False',
    },
  ];
  const [onlineStatus, setOnlineStatus] = useState([]);

  const statusOptions = [
    {
      text: 'Gecontroleerd',
      value: 'approved',
      icon: <IconChecks />,
    },
    {
      text: 'Wachten op 1e controle',
      value: 'unchecked',
      icon: <IconLoaderQuarter />,
    },
    {
      text: 'Wachten op 2e controle',
      value: 'pending',
      icon: <IconLoader />,
    },
    {
      text: 'Afgewezen',
      value: 'rejected',
      icon: <IconAlertCircleFilled />,
    },
  ];
  const [statusFilter, setStatusFilter] = useState([]);

  const handleRequestSort = (property) => {
    if (property === orderBy) {
      if (order === 'asc') {
        setOrder('desc');
      } else {
        setOrderBy('');
      }
    } else {
      setOrderBy(property);
      setOrder('asc');
    }
  };

  // UTILS FOR TOPICS FILTERING
  const [topicsFilter, setTopicsFilter] = useState([]);
  const [topics, setTopics] = useState([]);
  const [topicsSearch, setTopicsSearch] = useState('');
  const topicsOptions = topics.map((topic) => ({
    text: topic.name,
    value: topic.id,
  }));
  const [topicsCount, setTopicsCount] = useState(0);

  const fetchSources = async () => {
    let params = `?page=${page}&page_size=${rowsPerPage}`;
    if (orderBy) {
      params += `&ordering=${order === 'desc' ? '-' : ''}${orderBy}`;
    }
    if (search) {
      params += `&search=${search}`;
    }
    if (statusFilter.length) {
      params += statusFilter.map((status) => `&status=${status}`).join('');
    }
    if (onlineStatus.length) {
      params += onlineStatus.map((status) => `&online=${status}`).join('');
    }
    if (topicsFilter.length) {
      params += topicsFilter.map((topic) => `&topics=${topic}`).join('');
    }

    const response = await api.get(`${SOURCES_URL}${params}`);
    setSources(response.data.results);
    setTotalCount(response.data.count);
  };

  useEffect(() => {
    setSelectedSources([]);
    fetchSources();
  }, [page, rowsPerPage, order, orderBy, search, statusFilter, onlineStatus, topicsFilter]);

  useEffect(() => {
    setPage(1);
  }, [order, orderBy, search, statusFilter, onlineStatus, topicsFilter]);

  const fetchTopics = async () => {
    const response = await api.get(TOPICS_URL, {
      params: {
        search: topicsSearch,
      },
    });
    setTopics(response.data.results);
    setTopicsCount(response.data.count);
  };

  useEffect(() => {
    fetchTopics();
  }, [topicsSearch]);

  const getIcon = (source) => {
    switch (source.status) {
      case 'pending':
        return <IconLoader />;
      case 'unchecked':
        return <IconLoaderQuarter />;
      case 'rejected':
        return <IconAlertCircleFilled />;
      default:
        return <IconChecks />;
    }
  };

  const getStatusText = (source) => {
    switch (source.status) {
      case 'pending':
        return 'Wachten op 2e controle';
      case 'unchecked':
        return 'Wachten op 1e controle';
      case 'rejected':
        return 'Afgewezen';
      default:
        return 'Gecontroleerd';
    }
  };

  const toggleOnline = (source) => {
    api.patch(`${SOURCES_URL}${source.id}/`, {
      online: !source.online,
    }).then(() => {
      fetchSources();
    });
  };

  const handleBulkAction = async () => {
    if (bulkAction.length === 0) {
      return;
    }

    let swalText = '';

    if (['online', 'offline'].includes(bulkAction[0])) {
      swalText = 'Weet je zeker dat je de online/offline status van deese bronnen wilt wijzigen? Let op, alleen bronnen die tweemaal zijn geaccepteerd kunnen online/offline gezet worden.';
    }
    if (bulkAction[0] === 'delete') {
      swalText = 'Weet je zeker dat je de geselecteerde bronnen wilt verwijderen? Deze actie kan niet ongedaan worden gemaakt.';
    }

    const swal = await Swal2.fire({
      title: 'Bevestiging',
      text: swalText,
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Ja',
      cancelButtonText: 'Nee',
    });

    if (!swal.isConfirmed) {
      return;
    }

    if (['online', 'offline'].includes(bulkAction[0])) {
      const online = bulkAction[0] === 'online';

      try {
        const selectedCheckedSources = sources.filter((source) => selectedSources.includes(source.id) && source.status === 'approved');
        await Promise.all(
          selectedCheckedSources.map((source) => api.patch(`${SOURCES_URL}${source.id}/`, { online })),
        );
        fetchSources();
      } catch (error) {
        console.error("Failed to update sources' status:", error);
      }
    }

    if (bulkAction[0] === 'delete') {
      try {
        await Promise.all(
          selectedSources.map((source) => api.del(`${SOURCES_URL}${source}/`)),
        );
        fetchSources();
      } catch (error) {
        console.error("Failed to update sources' status:", error);
      }
    }

    setSelectedSources([]);
    setBulkAction([]);
    setAnchorElBulkAction(null);
  };

  useEffect(() => {
    handleBulkAction();
  }, [bulkAction]);

  return (
    <Container
      maxWidth="lg"
      sx={{
        ...containerStyles,
        minHeight: 'calc(100vh - 300px)',
      }}
      className="sources-table-view"
    >
      <Box sx={{ display: 'flex', gap: 2, mb: 2 }}>
        <BackofficeTitle title="Bronnenlijst" icon={<IconBooks />} />
        <input
          placeholder="Zoek..."
          value={search}
          onChange={(event) => {
            setSearch(event.target.value);
            setPage(1);
          }}
          className="backoffice-search"
        />
        <Button
          onClick={() => navigate('/backoffice/sources/new')}
          startIcon={<IconUpload />}
          className="btn btn-green"
        >
          Nieuwe bron
        </Button>
      </Box>
      <Box className="backoffice-bulk-actions">
        <Button
          className="btn btn-secondary"
          endIcon={<IconChevronDown />}
          onClick={(event) => setAnchorElBulkAction(event.currentTarget)}
          disabled={selectedSources.length === 0}
        >
          Bulk actie
        </Button>
        {selectedSources.length}
        {' geselecteerd'}
        <OptionsPopover
          onChange={(v) => setBulkAction([v])}
          options={[
            {
              text: 'Online zetten',
              value: 'online',
            },
            {
              text: 'Offline halen',
              value: 'offline',
            },
            {
              text: 'Verwijderen',
              value: 'delete',
            },
          ]}
          closeMenu={() => setAnchorElBulkAction(null)}
          value={bulkAction}
          open={openBulkAction}
          anchorEl={anchorElBulkAction}
        />
      </Box>
      <TableContainer className="backoffice-table">
        <Table>
          <TableHead className="table-header">
            <TableRow>
              <TableCell>
                <Checkbox
                  onChange={handleSelectAll}
                  checked={sources.length === selectedSources.length && sources.length > 0}
                  id="select-all"
                />
              </TableCell>
              <TableCell>
                <TableSelectLabel
                  onChange={setOnlineStatus}
                  options={onlineOptions}
                  value={onlineStatus}
                >
                  Gepubliceerd
                </TableSelectLabel>
              </TableCell>
              <TableCell>
                <TableSelectLabel
                  onChange={setStatusFilter}
                  options={statusOptions}
                  value={statusFilter}
                >
                  Status
                </TableSelectLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'title'}
                  direction={orderBy === 'title' ? order : 'asc'}
                  onClick={() => handleRequestSort('title')}
                >
                  Bestandsnaam
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSortLabel
                  active={orderBy === 'created_at'}
                  direction={orderBy === 'created_at' ? order : 'asc'}
                  onClick={() => handleRequestSort('created_at')}
                >
                  Gemaakt op
                </TableSortLabel>
              </TableCell>
              <TableCell>
                <TableSelectLabel
                  onChange={setTopicsFilter}
                  options={topicsOptions}
                  value={topicsFilter}
                  search={topicsSearch}
                  onSearchChange={setTopicsSearch}
                  count={topicsCount}
                >
                  Onderwerpen
                </TableSelectLabel>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {sources.map((source) => (
              <TableRow
                key={source.id}
                className="table-row"
                hover
                onClick={(e) => {
                  if (e.target.type !== 'checkbox') {
                    navigate(`/backoffice/sources/${source.id}`);
                  }
                }}
                style={{ cursor: 'pointer' }}
              >
                <TableCell className="checkbox-cell" onClick={(e) => e.stopPropagation()}>
                  <Checkbox
                    onChange={() => handleRowSelect(source.id)}
                    checked={selectedSources.includes(source.id)}
                    id={source.id}
                  />
                </TableCell>
                <TableCell>
                  <StatusSwitch
                    setStatus={() => toggleOnline(source)}
                    status={source.online}
                    disabled={source.status !== 'approved'}
                    onClick={(e) => e.stopPropagation()}
                  />
                </TableCell>
                <TableCell className="status">
                  {getIcon(source)}
                  {' '}
                  {getStatusText(source)}
                </TableCell>
                <TableCell className="table-cell">{source.title}</TableCell>
                <TableCell className="light">
                  {moment(source.created_at).format('DD MMMM YYYY, HH:mm')}
                </TableCell>
                <TableCell className="table-cell">
                  {source.topics.join(', ')}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={totalCount}
        page={page - 1}
        onPageChange={(event, newPage) => setPage(newPage + 1)}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={(event) => {
          setRowsPerPage(parseInt(event.target.value, 10));
          setPage(1);
        }}
        labelRowsPerPage="Rijen per pagina:"
        labelDisplayedRows={({ from, to, count }) => `${from}-${to} van ${count !== -1 ? count : `meer dan ${to}`}`}
        style={tablePaginationStyles}
      />
    </Container>
  );
}

export default SourcesTableView;
