import React from 'react';
import PropTypes from 'prop-types';
import FormInput from '../FormInput';

function FormIconInput({
  icon, value, onChange, placeholder,
}) {
  return (
    <div className="form-icon-input">
      {icon}
      <FormInput
        value={value}
        onChange={onChange}
        placeholder={placeholder}
      />
    </div>
  );
}

FormIconInput.propTypes = {
  icon: PropTypes.node.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
};

FormIconInput.defaultProps = {
  placeholder: '',
};

export default FormIconInput;
