import React from 'react';
import PropTypes from 'prop-types';

function FormLabel({ label, required, ...props }) {
  return (
    // eslint-disable-next-line
    <label className="form-label" {...props}>
      {label}
      {required && <span className="required">*</span>}
    </label>
  );
}

FormLabel.propTypes = {
  label: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

FormLabel.defaultProps = {
  required: false,
};

export default FormLabel;
