import React, {
  useContext, useEffect, useMemo, useState,
} from 'react';
import { Container } from '@mui/material';
import { TbSpy } from 'react-icons/tb';
import moment from 'moment';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { PRIVACY_POLICY_URL } from '../../Constants/URLS';
import { UserContext } from '../../Providers/UserProvider/UserProvider';
import LoadingScreen from '../LoadingScreen';

function PrivacyPolicy() {
  const [privacyPolicy, setPrivacyPolicy] = useState(null);
  const [notFound, setNotFound] = useState(false);
  const userContext = useContext(UserContext);
  const api = useMemo(() => apiUtilsHook(userContext), [userContext]);

  useEffect(() => {
    api.getNoAuth(`${PRIVACY_POLICY_URL}latest/`).then((response) => {
      setPrivacyPolicy(response.data);
    }).catch((error) => {
      // check if 404
      if (error.response.status === 404) {
        setNotFound(true);
      }
    });
  }, []);

  if (!privacyPolicy && !notFound) {
    return <LoadingScreen />;
  }

  return (
    <Container maxWidth="lg" sx={{ pt: 6, pb: 9, minHeight: '85vh' }}>
      <div className="heading">
        <TbSpy />
        <h1>Privacyverklaring</h1>
      </div>
      {notFound && <p>Privacyverklaring niet gevonden.</p>}
      {privacyPolicy && (
        <>
          <p style={{ marginBottom: 10 }}>
            <b>
              Laatst gewijzigd op
              {' '}
              {moment(privacyPolicy.created_at).format('DD-MM-YYYY')}
            </b>
          </p>
          {/* eslint-disable-next-line react/no-danger */}
          <p dangerouslySetInnerHTML={{ __html: privacyPolicy.text }} />
        </>
      )}
    </Container>
  );
}

export default PrivacyPolicy;
