import React, { useState, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import './Chatbot.scss';
import { Button } from '@mui/material';
import {
  IconFileText,
  IconMessages,
  IconSend, IconX,
} from '@tabler/icons-react';
import { PulseLoader } from 'react-spinners';
import {
  MESSAGES_URL,
} from '../../Constants/URLS';
import apiUtilsHook from '../../Utils/ApiUtilsHook';
import { UserContext } from '../../Providers/UserProvider/UserProvider';

function Chatbot({ pages }) {
  const location = useLocation();
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const [isOpen, setIsOpen] = useState(false);
  const [conversationId, setConversationId] = useState('');
  const [messages, setMessages] = useState([]);
  const [message, setMessage] = useState('');
  const navigate = useNavigate();

  // Determine if the chatbot should be rendered
  const shouldRenderChatbot = Object.values(pages).some(
    (page) => page.showChatbot && location.pathname === page.path,
  );

  if (!shouldRenderChatbot) {
    return null;
  }

  const sendMessage = async () => {
    if (!message.trim()) {
      return;
    }

    setMessages((prevMessages) => [
      ...prevMessages,
      {
        message,
        role: 'user',
      },
    ]);
    setMessage('');

    const data = {
      message,
      short: true,
    };

    if (conversationId) {
      data.conversation = conversationId;
    }

    api.post(MESSAGES_URL, data).then((r) => {
      setConversationId(r.data[0].conversation);
      setMessages((prevMessages) => [
        ...prevMessages.slice(0, -1),
        ...r.data,
      ]);
    });
  };

  const toggleChat = () => {
    setConversationId(null);
    setMessages([]);
    setIsOpen(!isOpen);
  };

  return (
    <div className="chatbot-container">
      {isOpen && (
        <div className="chat-window">
          <div className="messages-container">
            {messages.length > 0 && messages.slice().reverse()[0].role === 'user' && (
              <div className="message chatbot">
                <span className="message-sender">Chatbot</span>
                <PulseLoader size={4} className="message-loader" speedMultiplier={0.7} />
              </div>
            )}
            {messages.slice().reverse().map((msg) => (
              <div className={`message ${msg.role}`}>
                <span className="message-sender">{msg.role === 'user' ? userContext.user.first_name : 'Chatbot'}</span>
                {msg.message}
                {msg.sources && msg.sources.length > 0 && (
                  // eslint-disable-next-line
                  <div
                    className="sources"
                    onClick={() => navigate(`/chat/${conversationId}`, {
                      state: { messageId: msg.id, showSources: true },
                    })}
                  >
                    <div className="sources-count">
                      {msg.sources.length}
                    </div>
                    <div className="sources-icon">
                      <IconFileText />
                    </div>
                    <div className="sources-text">
                      Bijgevoegde bronnen
                    </div>
                  </div>
                )}
              </div>
            ))}
            <div className="message assistant">
              <span className="message-sender">Chatbot</span>
              Welkom bij de chatfunctie van het AO platform. Stel mij je vraag!
            </div>
          </div>
          <div className="message-input-container">
            <input
              type="text"
              onChange={(event) => setMessage(event.target.value)}
              onKeyDown={(event) => {
                if (event.key === 'Enter' && event.target.value.trim()) {
                  sendMessage();
                }
              }}
              value={message}
              placeholder="Stel je vraag hier..."
            />
            <Button
              className="btn btn-green btn-icon"
              onClick={sendMessage}
            >
              <IconSend />
            </Button>
          </div>
        </div>
      )}
      <div style={{ display: 'flex', justifyContent: 'end', marginTop: 20 }}>
        <Button
          className={`btn btn-icon ${isOpen ? 'btn-white' : 'btn-green'}`}
          onClick={toggleChat}
        >
          {isOpen ? <IconX /> : <IconMessages />}
        </Button>
      </div>
    </div>
  );
}

Chatbot.propTypes = {
  pages: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number.isRequired,
      path: PropTypes.string.isRequired,
      showChatbot: PropTypes.bool,
    }),
  ).isRequired,
};

export default Chatbot;
