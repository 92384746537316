import React, { useContext, useState } from 'react';
import ReactMarkdown from 'react-markdown';
import PropTypes from 'prop-types';
import {
  IconChevronDown,
  IconChevronUp,
  IconFileText,
  IconThumbDownFilled, IconThumbUpFilled,
} from '@tabler/icons-react';
import { MESSAGES_URL } from '../../../Constants/URLS';
import { UserContext } from '../../../Providers/UserProvider/UserProvider';
import apiUtilsHook from '../../../Utils/ApiUtilsHook';

function Message({ message, escalationCallback }) {
  const userContext = useContext(UserContext);
  const api = apiUtilsHook(userContext);
  const [sentiment, setSentiment] = useState(message.sentiment || 0);
  // eslint-disable-next-line
  const satisfied = sentiment === 6 ? 'positive' : (sentiment === -1 ? 'negative' : '');

  const [showSources, setShowSources] = useState(message.showSources || false);

  const handleThumbsPress = (positive) => {
    if (message.role === 'user') return;
    if (positive) {
      setSentiment(6);
    } else {
      setSentiment(-1);
    }
    api.patch(`${MESSAGES_URL}${message.id}/`, { sentiment: positive ? 6 : -1 }).then((response) => {
      // If the response is not the same as the message, we should add it to the conversation
      if (response.data.id !== message.id) {
        escalationCallback(response.data);
      }
    });
  };

  const handleSourceClick = (source) => {
    if (source.source_file || source.web_link) {
      window.open(source.source_file || source.web_link, '_blank', 'noopener,noreferrer');
    } else if (source.additional_text) {
      window.open(`/additional-text/${source.id}`, '_blank', 'noopener,noreferrer');
    }
  };

  return (
    <div className={`message ${message.role}`}>
      <span className="sender">{message.role === 'user' ? message.user : 'Chatbot'}</span>
      <p className="message-content">
        <ReactMarkdown>{message.message}</ReactMarkdown>
      </p>
      {message.role === 'assistant' && (
        <>
          <div className="message-actions">
            {message.sources.length > 0 ? (
              // eslint-disable-next-line
              <div
                className="sources-button"
                onClick={() => setShowSources(!showSources)}
              >
                <div className="counter">
                  {message.sources.length}
                </div>
                <div className="icon">
                  <IconFileText />
                </div>
                <div className="text">
                  Bijgevoegde bronnen
                </div>
                {showSources ? <IconChevronUp className="chevron" /> : <IconChevronDown className="chevron" />}
              </div>
            ) : <div />}
            <div>
              <button
                className={`positive ${satisfied === 'positive' ? 'active' : ''}`}
                onClick={() => handleThumbsPress(true)}
                type="button"
                aria-labelledby="positive-sentiment"
              >
                <IconThumbUpFilled />
              </button>
              <button
                className={`negative ${satisfied === 'negative' ? 'active' : ''}`}
                onClick={() => handleThumbsPress(false)}
                type="button"
                aria-labelledby="negative-sentiment"
              >
                <IconThumbDownFilled />
              </button>
            </div>
          </div>
          {message.sources && message.sources.length > 0 && showSources && (
            <div className="sources-container">
              {message.sources.map((source) => (
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  key={source.id}
                  className="source"
                  onClick={(e) => {
                    e.preventDefault();
                    handleSourceClick(source);
                  }}
                  href="#"
                  role="button"
                  tabIndex={0}
                  onKeyPress={(e) => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      handleSourceClick(source);
                    }
                  }}
                >
                  <div className="icon">
                    <IconFileText />
                  </div>
                  <h4>{source.title}</h4>
                </a>
              ))}
            </div>
          )}
        </>
      )}
    </div>
  );
}

Message.propTypes = {
  message: PropTypes.shape({
    id: PropTypes.string,
    message_type: PropTypes.string,
    conversation: PropTypes.string,
    message: PropTypes.string,
    role: PropTypes.string,
    // eslint-disable-next-line
        sources: PropTypes.object,
    sentiment: PropTypes.number,
    created_at: PropTypes.string,
    showSources: PropTypes.bool,
    user: PropTypes.string,
  }).isRequired,
  escalationCallback: PropTypes.func.isRequired,
};

export default Message;
