import React from 'react';
import PropTypes from 'prop-types';

function FormTextArea({
  value, onChange, placeholder, rows,
}) {
  return (
    <textarea
      value={value}
      onChange={(e) => onChange(e.target.value)}
      placeholder={placeholder}
      rows={rows}
      className="form-textarea"
    />
  );
}

FormTextArea.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  rows: PropTypes.number,
};

FormTextArea.defaultProps = {
  placeholder: '',
  rows: null,
};

export default FormTextArea;
