import React from 'react';
import PropTypes from 'prop-types';

function FormInput({
  value, onChange, placeholder, limit, error,
}) {
  return (
    <>
      <input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        className={`form-input ${error || (limit > 0 && value.length > limit) ? 'error' : ''}`}
        placeholder={placeholder}
      />
      {limit > 0 && (
        <small className={`form-input-limit ${limit > 0 && value.length > limit ? 'error' : ''}`}>
          {`${value.length}/${limit}`}
        </small>
      )}
    </>
  );
}

FormInput.propTypes = {
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  limit: PropTypes.number,
  error: PropTypes.bool,
};

FormInput.defaultProps = {
  placeholder: '',
  limit: 0,
  error: false,
};

export default FormInput;
